import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Card,
  Typography,
  Button,
  Accordion,
  AccordionHeader,
  AccordionBody,
  Dialog,
  DialogBody,
} from "@material-tailwind/react";
import { useProperties } from '../contexts/PropertiesContext';
import { colors } from '../utils/colors';
import Layout from '../components/layout/Layout';
import QuoteSelector from '../components/QuoteSelector';
import {
  HomeIcon,
  MapPinIcon,
  CalendarIcon,
  DocumentTextIcon,
  CheckCircleIcon,
  XCircleIcon,
  ArrowDownTrayIcon,
  ChevronDownIcon,
  ClockIcon,
  CheckIcon,
  PencilIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  StarIcon,
  CurrencyEuroIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import DeclineQuote from './DeclineQuote';
import AcceptQuote from './AcceptQuote';
import diagnosticsDetails from '../constants/diagnosticsDetails.json';
import axiosInstance from '../utils/axiosInstance';

const TrustBox = () => {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <div
      ref={ref}
      className="trustpilot-widget"
      data-locale="fr-FR"
      data-template-id="53aa8912dec7e10d38f59f36"
      data-businessunit-id="5f4375bc26242f0001c97b8b"
      data-style-height="130px"
      data-style-width="100%"
      data-theme="light"
      data-stars="5"
      data-review-languages="fr"
    >
      <a
        href="https://fr.trustpilot.com/review/dimo-diagnostic.net"
        target="_blank"
        rel="noopener noreferrer"
      >
        Trustpilot
      </a>
    </div>
  );
};

const Quote = ({ isPublic }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { token } = useLocation();

  // --- LOADING STATES ---
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // --- CONTEXT AND DATA STATES ---
  const [selectedQuote, setSelectedQuote] = useState(null);
  const { quotes, loading: quotesLoading, refreshProperties } = useProperties();
  const effectiveQuotes = isPublic ? null : quotes;
  const effectiveQuotesLoading = isPublic ? false : quotesLoading;

  // --- UI STATES ---
  const [openAccordion, setOpenAccordion] = useState(0);

  // --- DIALOG STATES ---
  const [dialogState, setDialogState] = useState({
    isDeclineModalOpen: false,
    isAcceptModalOpen: false,
    isDialogActionComplete: false,
    isRefreshing: false
  });

  const [callbackRequested, setCallbackRequested] = useState(false);
  const [isRequestingCallback, setIsRequestingCallback] = useState(false);

  // --- DIALOG HANDLERS ---
  const handleOpenDeclineModal = () => {
    setDialogState(prev => ({
      ...prev,
      isDeclineModalOpen: true,
      isDialogActionComplete: false
    }));
  };

  const handleOpenAcceptModal = () => {
    setDialogState(prev => ({
      ...prev,
      isAcceptModalOpen: true,
      isDialogActionComplete: false
    }));
  };

  const handleCloseDeclineModal = () => {
    if (!dialogState.isRefreshing) {
      setDialogState(prev => ({
        ...prev,
        isDeclineModalOpen: false,
        isDialogActionComplete: false
      }));
    }
  };

  const handleCloseAcceptModal = () => {
    if (!dialogState.isRefreshing) {
      setDialogState(prev => ({
        ...prev,
        isAcceptModalOpen: false,
        isDialogActionComplete: false
      }));
    }
  };

  const handleAcceptSuccess = async () => {
    setDialogState(prev => ({
      ...prev,
      isDialogActionComplete: true,
      isRefreshing: true
    }));
    setSelectedQuote(prev => ({ ...prev, quoteStatus: 'accepted' }));
    
    await refreshProperties(); // Pendant ce temps, selectedQuote est toujours défini
    setDialogState(prev => ({
      ...prev,
      isRefreshing: false,
      // La modale reste ouverte
    }));
  };
  
  const handleDeclineSuccess = async () => {
    setDialogState(prev => ({
      ...prev,
      isDialogActionComplete: true,
      isRefreshing: true
    }));
    setSelectedQuote(prev => ({ ...prev, quoteStatus: 'rejected' }));
    await refreshProperties();
    setDialogState(prev => ({
      ...prev,
      isRefreshing: false,
      // Ne pas fermer la modale, donc on ne modifie pas isDeclineModalOpen
    }));
  };

  const handleOpenAccordion = (value) => {
    setOpenAccordion(openAccordion === value ? 0 : value);
  };

  const handleCallbackRequest = async () => {
    if (callbackRequested || !selectedQuote) return;
    
    setIsRequestingCallback(true);
    try {
      const response = await axiosInstance.post(`/quotes/${selectedQuote.id}/callback`);
      if (response.data.success) {
        setCallbackRequested(true);
      }
    } catch (error) {
      console.error('Erreur lors de la demande de rappel:', error);
    } finally {
      setIsRequestingCallback(false);
    }
  };

  // --- DATA LOADING AND CONTEXT EFFECT ---
  useEffect(() => {
    if (effectiveQuotesLoading) {
      return;
    }

    if (effectiveQuotes === null || effectiveQuotes === undefined) {
      setError("Erreur lors du chargement des devis");
      setLoading(false);
      return;
    }

    if (effectiveQuotes.length === 0) {
      navigate('/dashboard');
      return;
    }

    if (!id) {
      const waitingQuotes = effectiveQuotes.filter(q => q.quoteStatus === 'waiting_response');
      const openQuote = waitingQuotes.length > 0 
        ? waitingQuotes.reduce((prev, current) => (prev.id > current.id ? prev : current))
        : null;
      
      if (openQuote) {
        navigate(`/quote/${openQuote.id}`);
      } else {
        const latestQuote = effectiveQuotes.reduce((prev, current) => (prev.id > current.id ? prev : current));
        navigate(`/quote/${latestQuote.id}`);
      }
    } else {
      const quote = effectiveQuotes.find(q => q.id.toString() === id);
      if (!quote) {
        navigate('/dashboard');
        return;
      }
      setSelectedQuote(quote);
    }
    
    setLoading(false);
  }, [effectiveQuotes, id, isPublic, effectiveQuotesLoading, navigate]);

  // --- HELPER FUNCTIONS ---
  const getQuoteStatusInfo = (status) => {
    switch(status) {
      case 'waiting_response':
        return {
          label: 'En attente de validation',
          color: colors.dimoPrimary,
          icon: ClockIcon,
          step: 1
        };
      case 'accepted':
        return {
          label: 'Devis validé',
          color: 'green',
          icon: CheckIcon,
          step: 2
        };
      case 'rejected':
        return {
          label: 'Devis refusé',
          color: 'red',
          icon: XCircleIcon,
          step: 1
        };
      default:
        return {
          label: 'Devis généré',
          color: colors.dimoPrimary,
          icon: PencilIcon,
          step: 1
        };
    }
  };

  // --- LOADING STATES RENDERING ---
  if ((loading || effectiveQuotesLoading) && !selectedQuote) {
    return (
      <Layout>
      <div className="mx-auto space-y-8 pb-8">
        {/* Première carte - Barre de progression */}
        <Card className="w-full p-8 bg-white shadow-lg hidden md:block">
          <div className="animate-pulse">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center gap-2">
                <div className="w-6 h-6 rounded-full bg-gray-200"></div>
                <div className="h-6 w-32 bg-gray-200 rounded"></div>
              </div>
              <div className="h-6 w-24 bg-gray-200 rounded"></div>
            </div>
            <div className="relative h-2 hidden md:flex justify-between gap-2">
              {[...Array(5)].map((_, i) => (
                <div key={i} className="w-[20%] bg-gray-200 rounded-full"></div>
              ))}
            </div>
            <div className="hidden md:flex justify-between mt-2">
              {[...Array(5)].map((_, i) => (
                <div key={i} className="w-[20%] text-center">
                  <div className="h-4 bg-gray-200 rounded mx-auto w-20"></div>
                </div>
              ))}
            </div>
          </div>
        </Card>

        {/* Deuxième carte - Informations principales */}
        <Card className="w-full p-8 bg-white shadow-lg">
          <div className="animate-pulse">
            <div className="flex flex-col md:flex-row items-start justify-between gap-4 md:gap-0 mb-8">
              <div className="w-full md:w-auto">
                <div className="h-4 w-24 bg-gray-200 rounded mb-2"></div>
                <div className="h-6 w-32 bg-gray-200 rounded"></div>
              </div>
              <div className="w-full md:w-40 h-10 bg-gray-200 rounded-xl"></div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-12 gap-6 mb-8">
              <div className="md:col-span-5">
                <div className="bg-gray-50 rounded-xl p-6 h-full">
                  <div className="space-y-4">
                    <div className="h-4 w-24 bg-gray-200 rounded"></div>
                    <div className="h-6 w-48 bg-gray-200 rounded"></div>
                    <div className="h-4 w-36 bg-gray-200 rounded"></div>
                    <div className="h-10 w-32 bg-gray-200 rounded"></div>
                    <div className="h-4 w-24 bg-gray-200 rounded"></div>
                    <div className="grid grid-cols-2 gap-3">
                      <div className="h-12 bg-gray-200 rounded-xl"></div>
                      <div className="h-12 bg-gray-200 rounded-xl"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="md:col-span-7 space-y-6">
                <div className="bg-gray-50 rounded-xl p-6">
                  <div className="space-y-4">
                    <div className="flex items-center space-x-3">
                      <div className="w-6 h-6 rounded-full bg-gray-200"></div>
                      <div className="h-6 w-32 bg-gray-200 rounded"></div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      {[...Array(4)].map((_, i) => (
                        <div key={i} className="h-4 bg-gray-200 rounded w-3/4"></div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="bg-gray-50 rounded-xl p-6">
                  <div className="space-y-4">
                    <div className="flex items-center space-x-3">
                      <div className="w-6 h-6 rounded-full bg-gray-200"></div>
                      <div className="h-6 w-32 bg-gray-200 rounded"></div>
                    </div>
                    <div className="h-4 bg-gray-200 rounded w-full"></div>
                  </div>
                </div>
              </div>
            </div>

            {/* Section documents légaux et diagnostics */}
            <div className="grid grid-cols-1 md:grid-cols-12 gap-6 mb-8">
              <div className="md:col-span-5">
                <div className="h-6 w-40 bg-gray-200 rounded mb-4"></div>
                <div className="flex flex-wrap gap-3">
                  {[...Array(3)].map((_, i) => (
                    <div key={i} className="h-9 w-32 bg-gray-200 rounded-xl"></div>
                  ))}
                </div>
              </div>

              <div className="md:col-span-7">
                <div className="h-6 w-40 bg-gray-200 rounded mb-4"></div>
                <div className="flex flex-wrap gap-3">
                  {[...Array(5)].map((_, i) => (
                    <div key={i} className="h-9 w-32 bg-gray-200 rounded-xl"></div>
                  ))}
                </div>
              </div>
            </div>

            {/* Section avantages */}
            <div>
              <div className="h-6 w-64 bg-gray-200 rounded mb-4"></div>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {[...Array(6)].map((_, i) => (
                  <div key={i} className="flex items-start space-x-3">
                    <div className="w-6 h-6 rounded-full bg-gray-200"></div>
                    <div className="flex-1 space-y-2">
                      <div className="h-4 bg-gray-200 rounded w-32"></div>
                      <div className="h-4 bg-gray-200 rounded w-full"></div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Card>

        {/* Carte Trustpilot */}
        <Card className="w-full p-8 bg-white shadow-lg">
          <div className="h-32 bg-gray-200 rounded w-full"></div>
        </Card>

        {/* Carte étapes */}
        <Card className="w-full p-8 bg-white shadow-lg">
          <div className="animate-pulse">
            <div className="h-6 w-72 bg-gray-200 rounded mx-auto mb-6"></div>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
              {[...Array(4)].map((_, i) => (
                <div key={i} className="flex flex-col items-center text-center">
                  <div className="w-16 h-16 rounded-full bg-gray-200 mb-4"></div>
                  <div className="h-6 w-32 bg-gray-200 rounded mb-2"></div>
                  <div className="h-4 bg-gray-200 rounded w-full"></div>
                </div>
              ))}
            </div>
          </div>
        </Card>
    </div>
    </Layout>
    );
  }

  if (error) return (
    <Layout>
    <div className="text-center p-6">
      <Typography color="red" className="text-xl">
        {error}
      </Typography>
    </div>
    </Layout>
  );

  if (!selectedQuote && !loading) return (
    <Layout>
      <div className="text-center p-6">
        <Typography color="red" className="text-xl">
          Devis non trouvé
        </Typography>
      </div>
    </Layout>
  );

  const statusInfo = getQuoteStatusInfo(selectedQuote.quoteStatus);

  // Modification du diagnostic amiante en fonction du projet
  const modifiedDiagnostics = selectedQuote.diagnostics?.map(diagnostic => {
    if (diagnostic === "Amiante") {
      if (selectedQuote.porject === "sell") {
        return "Amiante Vente";
      } else if (selectedQuote.porject === "rente") {
        return "Amiante Location";
      }else{
        return "Amiante Vente";
      }
    }
    return diagnostic;
  });

  // Mise à jour de selectedQuote avec les diagnostics modifiés
  const effectiveQuote = {
    ...selectedQuote,
    diagnostics: modifiedDiagnostics
  };

  return (
    <Layout>
      <Helmet>
        <script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async></script>
      </Helmet>
      <div className="mx-auto space-y-8 pb-8">
        {!isPublic && effectiveQuotes && effectiveQuotes.length > 1 && (
        <Card className="w-full p-8 bg-white shadow-lg">
            <QuoteSelector
              selectedQuote={id}
              setSelectedQuote={(quoteId) => {
                navigate(`/quote/${quoteId}`);
              }}
              quotes={effectiveQuotes}
              isLoading={effectiveQuotesLoading}
            />
          </Card>
        )}

        {selectedQuote?.quoteStatus !== "waiting_quote" && selectedQuote && (
          <div>
            <Card className="w-full p-8 bg-white shadow-lg hidden md:block">
              <div className="flex items-center justify-between md:mb-4">
            <div className="flex items-center gap-2">
              <statusInfo.icon className="h-6 w-6" style={{ color: statusInfo.color }} />
              <Typography variant="h6" style={{ color: statusInfo.color }}>
                {statusInfo.label}
              </Typography>
            </div>
            <Typography variant="small" className="text-gray-600">
              {new Date().toLocaleDateString('fr-FR', { 
                day: 'numeric',
                month: 'long',
                year: 'numeric'
              })}
            </Typography>
          </div>
              <div className="relative h-2 hidden md:flex justify-between gap-2">
            <div className="w-[20%] bg-gray-200 rounded-full overflow-hidden">
              <div 
                className="h-full transition-all duration-500 rounded-full"
                style={{ 
                  width: statusInfo.step >= 1 ? '100%' : '0%',
                  backgroundColor: statusInfo.step >= 1 ? statusInfo.color : 'transparent'
                }}
              />
            </div>
            <div className="w-[20%] bg-gray-200 rounded-full overflow-hidden">
              <div 
                className="h-full transition-all duration-500 rounded-full"
                style={{ 
                  width: statusInfo.step >= 2 ? '100%' : '0%',
                  backgroundColor: statusInfo.step >= 2 ? statusInfo.color : 'transparent'
                }}
              />
            </div>
            <div className="w-[20%] bg-gray-200 rounded-full overflow-hidden">
              <div 
                className="h-full transition-all duration-500 rounded-full"
                style={{ 
                  width: statusInfo.step >= 3 ? '100%' : '0%',
                  backgroundColor: statusInfo.step >= 3 ? statusInfo.color : 'transparent'
                }}
              />
            </div>
            <div className="w-[20%] bg-gray-200 rounded-full overflow-hidden">
              <div 
                className="h-full transition-all duration-500 rounded-full"
                style={{ 
                  width: statusInfo.step >= 4 ? '100%' : '0%',
                  backgroundColor: statusInfo.step >= 4 ? statusInfo.color : 'transparent'
                }}
              />
            </div>
            <div className="w-[20%] bg-gray-200 rounded-full overflow-hidden">
              <div 
                className="h-full transition-all duration-500 rounded-full"
                style={{ 
                  width: statusInfo.step >= 5 ? '100%' : '0%',
                  backgroundColor: statusInfo.step >= 5 ? statusInfo.color : 'transparent'
                }}
              />
            </div>
          </div>
              <div className="hidden md:flex justify-between mt-2">
            <div className="w-[20%] text-center">
              <Typography variant="small" className="text-gray-600">Devis généré</Typography>
            </div>
            <div className="w-[20%] text-center">
              <Typography variant="small" className="text-gray-600">Validation</Typography>
            </div>
            <div className="w-[20%] text-center">
              <Typography variant="small" className="text-gray-600">RDV planifié</Typography>
            </div>
            <div className="w-[20%] text-center">
              <Typography variant="small" className="text-gray-600">Intervention</Typography>
            </div>
            <div className="w-[20%] text-center">
              <Typography variant="small" className="text-gray-600">Rapport</Typography>
            </div>
          </div>
          </Card>

            <Card className="w-full p-8 bg-white shadow-lg mt-0 md:mt-8">
              <div className="flex flex-col md:flex-row items-start justify-between gap-4 md:gap-0 mb-8">
            <div>
              <Typography variant="small" className="text-gray-600 mb-1">
                Référence
              </Typography>
              <Typography variant="h5" className="text-black">
                Devis n°{selectedQuote.id}
              </Typography>
            </div>
            <Button
              size="md"
                  className="w-full md:w-auto flex items-center justify-center gap-2 rounded-xl"
                  style={{ backgroundColor: colors.dimoPrimary }}
              onClick={() => window.open(selectedQuote.quoteFile, '_blank')}
            >
                  <ArrowDownTrayIcon className="h-4 w-4 text-white" />
              Télécharger le devis
            </Button>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-12 gap-6 mb-8">
            <div className="md:col-span-5">
              <div className="bg-gray-50 rounded-xl p-6 h-full flex flex-col">
                <div className="flex flex-col">
                  <Typography variant="small" className="text-gray-600 mb-1">
                    Client
                  </Typography>
                  <Typography variant="h5" className="text-black mb-0">
                    {selectedQuote.name}
                  </Typography>
                  <div className="flex flex-col gap-1 mb-4">
                    <Typography variant="small" className="text-gray-600 flex items-center gap-2">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                      </svg>
                      {selectedQuote.email}
                    </Typography>
                  </div>
                  <div className="mb-6">
                    <div className="flex items-center gap-4">
                          <Typography variant="h2" className="text-black font-bold text-2xl md:text-4xl">
                        {selectedQuote.value ? `${Math.round(selectedQuote.value * 1.2).toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €` : 'Montant non défini'}
                      </Typography>
                      {selectedQuote.offer && (
                        <div className="px-3 py-1 bg-green-50 text-green-700 rounded-lg text-sm font-medium">
                          {selectedQuote.offer}
                        </div>
                      )}
                    </div>
                    <Typography variant="small" className="text-gray-600">
                      {selectedQuote.value ? `${selectedQuote.value.toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} € HT` : ''}
                    </Typography>
                  </div>
                        {selectedQuote.quoteStatus === "waiting_response" && (
                          <>
                  <div className="grid grid-cols-2 gap-3">
                    <Button
                      size="lg"
                      variant="outlined"
                      color="red"
                               className="flex items-center justify-center gap-2 rounded-xl w-full md:w-auto"
                               onClick={handleOpenDeclineModal}
                    >
                               <XCircleIcon className="h-5 w-5 min-w-[14px]" />
                      Refuser
                    </Button>
                    <Button
                      size="lg"
                               className="flex items-center justify-center gap-2 rounded-xl w-full md:w-auto"
                      style={{ backgroundColor: colors.dimoPrimary }}
                               onClick={handleOpenAcceptModal}
                    >
                      <CheckCircleIcon className="h-5 w-5" />
                      Accepter
                    </Button>
                  </div>
                        </>
                        )}
                        {selectedQuote.quoteStatus === "accepted" && (
                          <div className="col-span-2 flex flex-col 2xl:flex-row items-start 2xl:items-center gap-4">
                            <div className="w-12 h-12 rounded-full bg-green-50 flex items-center justify-center flex-shrink-0">
                              <CheckCircleIcon className="h-6 w-6 text-green-500" />
                            </div>
                            <div className="flex-grow">
                              <Typography variant="h6" className="text-black">
                                Vous avez accepté ce devis
                              </Typography>
                              <Typography className="text-gray-700 text-sm">
                                Notre équipe vous contactera très prochainement, afin de planifier le rendez-vous.
                              </Typography>
                            </div>
                          </div>
                        )}
                        {selectedQuote.quoteStatus === "rejected" && (
                          <div className="col-span-2 flex flex-col 2xl:flex-row items-start gap-4">
                            <div className="w-12 h-12 rounded-full bg-red-50 flex items-center justify-center flex-shrink-0">
                              <XCircleIcon className="h-6 w-6 text-red-500" />
                            </div>
                            <div className="flex-grow flex flex-col 2xl:flex-row 2xl:items-center justify-between gap-4">
                              <div>
                                <Typography variant="h6" className="text-black">
                                  Vous avez refusé ce devis
                                </Typography>
                                <Typography className="text-gray-700 text-sm">
                                  Vous avez changé d'avis ? Vous pouvez toujours l'accepter.
                                </Typography>
                              </div>
                              <div className="flex-shrink-0">
                                <Button
                                  size="sm"
                                  className="flex items-center justify-center gap-2 rounded-xl"
                                  style={{ backgroundColor: colors.dimoPrimary }}
                                  onClick={handleOpenAcceptModal}
                                >
                                  <CheckCircleIcon className="h-4 w-4 text-white" />
                                  <span>Accepter</span>
                                </Button>
                              </div>
                            </div>
                          </div>
                        )}
                </div>
              </div>
            </div>

            <div className="md:col-span-7 space-y-6">
              <div className="bg-gray-50 rounded-xl p-6">
                <div className="flex items-center space-x-3 mb-3">
                  <HomeIcon className="h-6 w-6" style={{ color: colors.dimoPrimary }} />
                  <Typography variant="h6" className="text-black">
                        Votre bien
                  </Typography>
                </div>
                    <div className="ml-9 grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div className="space-y-2">
                  <Typography variant="small" className="text-gray-700">
                    Type : {selectedQuote.typeProperty}
                  </Typography>
                  <Typography variant="small" className="text-gray-700">
                    Surface : {selectedQuote.square} m²
                  </Typography>
                      </div>
                      <div className="space-y-2">
                  <Typography variant="small" className="text-gray-700">
                    Construction : {selectedQuote.buildingYear}
                  </Typography>
                        <Typography variant="small" className="text-gray-700">
                          Temps d'intervention estimé : {selectedQuote.interventionTime || "Non défini"}
                  </Typography>
                      </div>
                </div>
              </div>

              <div className="bg-gray-50 rounded-xl p-6">
                <div className="flex items-center space-x-3 mb-3">
                  <MapPinIcon className="h-6 w-6" style={{ color: colors.dimoPrimary }} />
                  <Typography variant="h6" className="text-black">
                    Localisation
                  </Typography>
                </div>
                <div className="ml-9">
                  <Typography variant="small" className="text-gray-700">
                    {selectedQuote.address}
                  </Typography>
                </div>
              </div>
            </div>
          </div>

              <div className="grid grid-cols-1 md:grid-cols-12 gap-6 mb-8">
                <div className="md:col-span-5">
              <Typography variant="h6" className="text-black mb-4">
                    Documents légaux
              </Typography>
              <div className="flex flex-wrap gap-3">
                    <button
                      className="px-4 py-2 bg-gray-50 text-gray-700 rounded-xl text-sm flex items-center hover:bg-gray-100 transition-colors duration-200"
                      onClick={() => window.open('/assurance.pdf', '_blank')}
                    >
                      <ShieldCheckIcon className="h-5 w-5 mr-2" style={{ color: colors.dimoPrimary }} />
                      Assurance
                    </button>

                    <button
                      className="px-4 py-2 bg-gray-50 text-gray-700 rounded-xl text-sm flex items-center hover:bg-gray-100 transition-colors duration-200"
                      onClick={() => window.open('/certification', '_blank')}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mr-2" style={{ color: colors.dimoPrimary }}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.26 10.147a60.438 60.438 0 0 0-.491 6.347A48.62 48.62 0 0 1 12 20.904a48.62 48.62 0 0 1 8.232-4.41 60.46 60.46 0 0 0-.491-6.347m-15.482 0a50.636 50.636 0 0 0-2.658-.813A59.906 59.906 0 0 1 12 3.493a59.903 59.903 0 0 1 10.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.717 50.717 0 0 1 12 13.489a50.702 50.702 0 0 1 7.74-3.342M6.75 15a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm0 0v-3.675A55.378 55.378 0 0 1 12 8.443m-7.007 11.55A5.981 5.981 0 0 0 6.75 15.75v-1.5" />
                      </svg>
                      Certifications
                    </button>

                    <button
                      className="px-4 py-2 bg-gray-50 text-gray-700 rounded-xl text-sm flex items-center hover:bg-gray-100 transition-colors duration-200"
                      onClick={() => window.open('/cgvu.pdf', '_blank')}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mr-2" style={{ color: colors.dimoPrimary }}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
                      </svg>
                      CGVU
                    </button>
                  </div>
                </div>

                <div className="md:col-span-7">
                  <Typography variant="h6" className="text-black mb-4">
                    Diagnostics réalisés
                  </Typography>
                  <div className="flex flex-wrap gap-3">
                    {[...effectiveQuote.diagnostics].map((diagnostic, index) => (
                  <span
                    key={index}
                    className="px-4 py-2 bg-gray-50 text-gray-700 rounded-xl text-sm flex items-center hover:bg-gray-100 transition-colors duration-200"
                  >
                    <DocumentTextIcon className="h-5 w-5 mr-2" style={{ color: colors.dimoPrimary }} />
                    {diagnostic}
                  </span>
                ))}
              </div>
            </div>
              </div>

          <div>
            <Typography variant="h6" className="text-black mb-4">
                  Pourquoi choisir Dimo Diagnostic ?
            </Typography>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div className="flex items-start space-x-3">
                <ShieldCheckIcon className="h-6 w-6 mt-1" style={{ color: colors.dimoPrimary }} />
                <div>
                  <Typography variant="small" className="font-semibold text-black">
                    Expertise certifiée
                  </Typography>
                  <Typography variant="small" className="text-gray-700">
                        Nos diagnostiqueurs 100% salariés sont certifiés, assurés et formés régulièrement aux dernières normes
                  </Typography>
                </div>
              </div>
              <div className="flex items-start space-x-3">
                <UserGroupIcon className="h-6 w-6 mt-1" style={{ color: colors.dimoPrimary }} />
                <div>
                  <Typography variant="small" className="font-semibold text-black">
                    Service client dédié
                  </Typography>
                  <Typography variant="small" className="text-gray-700">
                    Une équipe à votre écoute pour vous accompagner à chaque étape
                  </Typography>
                </div>
              </div>
              <div className="flex items-start space-x-3">
                <StarIcon className="h-6 w-6 mt-1" style={{ color: colors.dimoPrimary }} />
                <div>
                  <Typography variant="small" className="font-semibold text-black">
                        Noté Excellent sur Truspilot
                  </Typography>
                  <Typography variant="small" className="text-gray-700">
                        Plus de 80000 clients nous ont fait confiance
                  </Typography>
                </div>
              </div>
              <div className="flex items-start space-x-3">
                <CurrencyEuroIcon className="h-6 w-6 mt-1" style={{ color: colors.dimoPrimary }} />
                <div>
                  <Typography variant="small" className="font-semibold text-black">
                        Meilleurs prix
                  </Typography>
                  <Typography variant="small" className="text-gray-700">
                        Tarif parmi les plus attractifs du marché
                      </Typography>
                    </div>
                  </div>
                  <div className="flex items-start space-x-3">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mt-1" style={{ color: colors.dimoPrimary }}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
                    </svg>
                    <div>
                      <Typography variant="small" className="font-semibold text-black">
                        Réactivité
                      </Typography>
                      <Typography variant="small" className="text-gray-700">
                        Des interventions et des rendus rapides
                      </Typography>
                    </div>
                  </div>
                  <div className="flex items-start space-x-3">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mt-1" style={{ color: colors.dimoPrimary }}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605" />
                    </svg>
                    <div>
                      <Typography variant="small" className="font-semibold text-black">
                        Une expérience fluide
                      </Typography>
                      <Typography variant="small" className="text-gray-700">
                        Un parcours digitalisé pour simplifier vos démarches
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </Card>
          </div>
        )}

        {selectedQuote.quoteStatus === "waiting_quote" && (
          <Card className="w-full p-8 bg-white shadow-lg">
            <div className="text-center space-y-6">
              <Typography variant="h4" className="text-black">
                Devis en cours de préparation
              </Typography>
              <Typography className="text-gray-700">
                Notre équipe est en train de préparer votre devis personnalisé. Nous vous contacterons dès qu'il sera disponible pour discuter des détails et répondre à vos questions.
              </Typography>
              <div className="flex flex-col sm:flex-row gap-4 justify-center">
                <Button
                  size="lg"
                  variant="outlined"
                  className="flex items-center justify-center gap-2 rounded-xl"
                  onClick={() => window.location.href = 'tel:+33411900298'}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                  </svg>
                  Nous appeler
                </Button>
                <Button
                  size="lg"
                  className="flex items-center justify-center gap-2 rounded-xl"
                  style={{ backgroundColor: colors.dimoPrimary }}
                  onClick={() => window.location.href = `mailto:${selectedQuote.commericalEmail}`}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                  </svg>
                  Nous contacter
                </Button>
              </div>
            </div>
          </Card>
        )}

        <Card className="w-full p-8 bg-white shadow-lg">
          <TrustBox />
        </Card>

        {selectedQuote.quoteStatus !== "waiting_quote" && (
        <Card className="w-full p-8 bg-white shadow-lg">
          <Typography variant="h4" className="text-black mb-3">
            Détails des diagnostics
          </Typography>
          <Typography className="text-gray-700 mb-6">
            Découvrez en détail chaque diagnostic inclus dans votre devis. Cliquez sur chaque diagnostic pour comprendre son importance, son déroulement et sa validité. Ces informations vous aideront à mieux comprendre la valeur de chaque diagnostic.
          </Typography>
          <div className="space-y-4">
              {["Forfait fixe", ...(effectiveQuote.diagnostics || []).filter(d => d !== "Forfait fixe")].map((diagnostic, index) => {
                const details = diagnosticsDetails.find(d => d.title === diagnostic || d.id === diagnostic);
              if (!details) return null;
              
              return (
                <Accordion
                  key={index}
                  open={openAccordion === index + 1}
                  icon={
                    <ChevronDownIcon
                      strokeWidth={2.5}
                      className={`mx-auto h-5 w-5 transition-transform ${openAccordion === index + 1 ? "rotate-180" : ""}`}
                    />
                  }
                >
                  <AccordionHeader
                    onClick={() => handleOpenAccordion(index + 1)}
                    className="border-b-0 transition-colors hover:!text-dimoPrimary py-4"
                  >
                    <div className="flex items-center">
                      <DocumentTextIcon className="h-6 w-6 mr-3" style={{ color: colors.dimoPrimary }} />
                      <span className="font-semibold">{details.title}</span>
                    </div>
                  </AccordionHeader>
                  <AccordionBody className="pt-0 px-4">
                      <div className="bg-gray-50 rounded-xl p-6 space-y-6">
                        <div className="prose max-w-none">
                          {typeof details.description === 'string' ? (
                            <Typography className="text-gray-700 leading-relaxed">
                        {details.description}
                      </Typography>
                          ) : (
                            details.description.map((item, index) => (
                              <Typography key={index} className="text-gray-700 leading-relaxed mb-2">
                                {item}
                              </Typography>
                            ))
                          )}
                        </div>

                        {details.note && (
                          <div className="bg-blue-50 border-l-4 border-blue-500 p-4 rounded-r-xl">
                            <Typography className="text-gray-700">
                              💡 {details.note}
                            </Typography>
                          </div>
                        )}

                        {details.validity && (
                          <div className="bg-green-50 border-l-4 border-green-500 p-4 rounded-r-xl">
                            <Typography className="text-gray-700">
                              <strong>Validité :</strong> {details.validity}
                            </Typography>
                          </div>
                        )}

                        {details.points && (
                          <div className="space-y-3">
                            <Typography variant="h6" className="text-black mb-2">
                              Points clés :
                      </Typography>
                      <ul className="space-y-2">
                        {details.points.map((point, pointIndex) => (
                                <li key={pointIndex} className="flex items-start gap-3 text-gray-700">
                                  <div className="mt-1.5 min-w-[6px]">
                              <div className="h-1.5 w-1.5 rounded-full bg-dimoPrimary"></div>
                            </div>
                                  <span className="leading-relaxed">{point}</span>
                          </li>
                        ))}
                      </ul>
                          </div>
                        )}

                        {details.dispense && (
                          <div className="space-y-3">
                            <Typography variant="h6" className="text-black mb-2">
                              Conditions de dispense :
                            </Typography>
                            <ul className="space-y-2">
                              {details.dispense.map((point, pointIndex) => (
                                <li key={pointIndex} className="flex items-start gap-3 text-gray-700">
                                  <div className="mt-1.5 min-w-[6px]">
                                    <div className="h-1.5 w-1.5 rounded-full bg-dimoPrimary"></div>
                                  </div>
                                  <span className="leading-relaxed">{point}</span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}

                        {details.avantages && (
                          <div className="space-y-3">
                            <Typography variant="h6" className="text-black mb-2">
                              Avantages :
                            </Typography>
                            <ul className="space-y-2">
                              {details.avantages.map((avantage, avantageIndex) => (
                                <li key={avantageIndex} className="flex items-start gap-3 text-gray-700">
                                  <div className="mt-1.5 min-w-[6px]">
                                    <div className="h-1.5 w-1.5 rounded-full bg-dimoPrimary"></div>
                                  </div>
                                  <span className="leading-relaxed">{avantage}</span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                    </div>
                  </AccordionBody>
                </Accordion>
              );
            })}
          </div> 
        </Card>
        )}

        <Card className="w-full p-8 bg-white shadow-lg">
          <Typography variant="h4" className="text-black mb-6 text-center">
            Comment se déroule votre diagnostic ?
          </Typography>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            <div className="flex flex-col items-center text-center">
              <div className="w-16 h-16 rounded-full bg-green-50 flex items-center justify-center mb-4">
                <CalendarIcon className="h-8 w-8" style={{ color: colors.dimoPrimary }} />
              </div>
              <Typography variant="h6" className="text-black mb-2">
                1. Prise de rendez-vous
              </Typography>
              <Typography className="text-gray-700">
                Après validation du devis, nous planifions ensemble la visite selon vos disponibilités
              </Typography>
            </div>
            <div className="flex flex-col items-center text-center">
              <div className="w-16 h-16 rounded-full bg-green-50 flex items-center justify-center mb-4">
                <DocumentTextIcon className="h-8 w-8" style={{ color: colors.dimoPrimary }} />
              </div>
              <Typography variant="h6" className="text-black mb-2">
                2. Préparation du rendez-vous
              </Typography>
              <Typography className="text-gray-700">
              Vous êtes invité à fournir les éléments essentiels au bon déroulé des opérations
              </Typography>
            </div>
            <div className="flex flex-col items-center text-center">
              <div className="w-16 h-16 rounded-full bg-green-50 flex items-center justify-center mb-4">
                <HomeIcon className="h-8 w-8" style={{ color: colors.dimoPrimary }} />
              </div>
              <Typography variant="h6" className="text-black mb-2">
                3. Visite sur place
              </Typography>
              <Typography className="text-gray-700">
              Notre diagnostiqueur analyse les données collectées et réalise les contrôles nécessaires                </Typography>
            </div>
            <div className="flex flex-col items-center text-center">
              <div className="w-16 h-16 rounded-full bg-green-50 flex items-center justify-center mb-4">
                <ArrowDownTrayIcon className="h-8 w-8" style={{ color: colors.dimoPrimary }} />
              </div>
              <Typography variant="h6" className="text-black mb-2">
                4. Livraison
              </Typography>
              <Typography className="text-gray-700">
              Une fois le paiement validé, votre rapport complet sera disponible en téléchargement dans votre espace client.
              </Typography>
            </div>
          </div>
        </Card>

        <Card className="w-full p-8 bg-white shadow-lg">
          <div className="text-center max-w-3xl mx-auto">
            <Typography variant="h4" className="text-black mb-4">
              Besoin d'aide pour votre décision ?
            </Typography>
            <Typography className="text-gray-700 mb-8">
              Notre équipe est à votre disposition pour répondre à toutes vos questions
            </Typography>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <Button
                size="lg"
                variant="outlined"
                className="flex items-center justify-center gap-2 rounded-xl"
                onClick={() => window.location.href = 'tel:+33411900298'}
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                </svg>
                Nous appeler
              </Button>
              <Button
                size="lg"
                className="flex items-center justify-center gap-2 rounded-xl"
                style={{ backgroundColor: colors.dimoPrimary }}
                onClick={handleCallbackRequest}
                disabled={callbackRequested || isRequestingCallback}
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
                </svg>
                {callbackRequested ? "Demande envoyée" : "Être rappelé"}
              </Button>
            </div>
            {callbackRequested && (
              <Typography variant="small" className="text-green-600 mt-4">
                Votre demande a bien été prise en compte. Notre équipe vous recontactera dans les plus brefs délais.
              </Typography>
            )}
          </div>
        </Card>
      </div>

      <Dialog 
        open={dialogState.isDeclineModalOpen} 
        handler={handleCloseDeclineModal}
        size="lg"
        className="max-h-[calc(100dvh-2rem)] overflow-y-auto mx-2 md:mx-auto"
      >
        <DialogBody className="p-0 relative">
          <button
            type="button"
            onClick={handleCloseDeclineModal}
            className="absolute top-2 right-2 z-10 p-2 rounded-lg hover:bg-gray-100 transition-colors"
          >
            <XMarkIcon className="h-5 w-5 text-gray-500" />
          </button>
          <DeclineQuote 
            isPublic={isPublic}
            token={token}
            id={id}
            onSuccess={handleDeclineSuccess}
            isComplete={dialogState.isDialogActionComplete}
            onCancel={handleCloseDeclineModal}
          />
        </DialogBody>
      </Dialog>

      <Dialog 
        open={dialogState.isAcceptModalOpen} 
        handler={handleCloseAcceptModal}
        size="lg"
        className="max-h-[calc(100dvh-2rem)] overflow-y-auto mx-2 md:mx-auto"
      >
        <DialogBody className="p-0 relative">
          <button
            type="button"
            onClick={handleCloseAcceptModal}
            className="absolute top-2 right-2 z-10 p-2 rounded-lg hover:bg-gray-100 transition-colors"
          >
            <XMarkIcon className="h-5 w-5 text-gray-500" />
          </button>
          <AcceptQuote 
            isPublic={isPublic}
            token={token}
            id={id}
            onSuccess={handleAcceptSuccess}
            isComplete={dialogState.isDialogActionComplete}
          />
        </DialogBody>
      </Dialog>
    </Layout>
  );
};

export default Quote;