import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { colors } from "../../utils/colors";

export default function Layout({ children }) {
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const closeSidebar = () => setIsSidebarOpen(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setIsSidebarOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="flex flex-col min-h-dvh bg-gray-100">
      {/* Bandeau promotionnel - maintenant en position fixe au-dessus de tout */}
      {/* div speciale pour ordinateur */}
      <div 
        onClick={() => navigate('/deals')}
        className="hidden md:block  lg:ml-64 fixed top-0 left-0 right-0 z-50 text-black text-center py-2 px-4 text-sm md:text-base font-medium cursor-pointer hover:opacity-90 transition-opacity"
        style={{ backgroundColor: colors.dimoPop }}
      >
        ✨ Offres spéciales dans nos bons plans : découvrez-les aujourd'hui !
      </div>
      {/* mobile */}
      <div 
        onClick={() => navigate('/deals')}
        className="md:hidden sticky top-0 left-0 right-0 z-50 text-black text-center py-2 px-4 text-sm font-medium cursor-pointer hover:opacity-90 transition-opacity"
        style={{ backgroundColor: colors.dimoPop }}
      >
        ✨ Cliquez ici pour découvrir nos bons plans !
      </div>
      {/* Div pour compenser la hauteur du bandeau fixe */}
      <div className="h-10 hidden md:block"></div>
      <Header toggleSidebar={toggleSidebar} />
      <Sidebar isOpen={isSidebarOpen} closeSidebar={closeSidebar} />
      {/* Overlay avec animation */}
      <div 
        className={`
          fixed inset-0 bg-black transition-opacity duration-500 ease-in-out lg:hidden
          ${isSidebarOpen ? 'opacity-25 z-40' : 'opacity-0 pointer-events-none'}
        `}
        onClick={closeSidebar}
      ></div>
      <main className="flex-grow p-4 lg:ml-64 transition-all duration-150 ease-in-out">
        
        {children}
        <Footer/>
      </main>
    </div>
  );
}