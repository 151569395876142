import React, { useEffect } from "react";
import { Select, Option, Spinner, Typography } from "@material-tailwind/react";
import { HomeIcon } from "@heroicons/react/24/outline";
import { colors } from "../utils/colors";

export default function PropertySelector({ selectedProperty, setSelectedProperty, properties = [], isLoading }) {
  useEffect(() => {
    if (!isLoading && Array.isArray(properties) && properties.length > 0 && !selectedProperty) {
      setSelectedProperty(String(properties[0].id));
    }
  }, [isLoading, properties, selectedProperty, setSelectedProperty]);

  if (isLoading) {
    return (
      <div className="p-4 flex items-center justify-center">
        <Spinner className="h-8 w-8" style={{ color: colors.dimoPrimary }} />
      </div>
    );
  }

  if (!Array.isArray(properties)) {
    return (
      <div className="p-4 text-red-500">
        <p>Erreur : Les dossiers ne sont pas disponibles.</p>
      </div>
    );
  }

  return (
    <div className="border-b border-gray-200">
      <Typography variant="h6" color="black" className="mb-1">
        Sélectionnez un dossier
      </Typography>
      <Select
        size="lg"
        className="propSelector"
        label=""
        value={selectedProperty ? String(selectedProperty) : ""}
        onChange={(value) => setSelectedProperty(value)}
        labelProps={{
          className: 'before:mr-0 after:ml-0',
        }}
      >
        {properties.map((property, index) => (
          <Option key={`${property.id}-${index}`} value={String(property.id)}>
            <div className="flex items-center gap-2 propSelector-option">
              <HomeIcon className="h-6 w-6 flex-shrink-0 hidden sm:block" style={{ color: colors.dimoPrimary }} />
              <span className="text-base leading-tight truncate max-w-full">{property.id} - {property.address || 'Adresse non définie'}</span>
            </div>
          </Option>
        ))}
      </Select>
    </div>
  );
}

