import React, { useState } from "react";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  Card,
  CardBody,
  Typography,
  Button,
} from "@material-tailwind/react";
import { CreditCardIcon, BanknotesIcon, ArrowPathIcon } from "@heroicons/react/24/outline";

const PaymentOption = ({ icon, title, description, popular = false, onClick }) => (
  <Card className="w-full mb-4 cursor-pointer hover:bg-gray-50 transition-colors duration-100 ease-in-out" onClick={onClick}>
    <CardBody className="flex items-center p-4">
      <div className="rounded-full bg-blue-50 p-3 mr-4">
        {icon}
      </div>
      <div className="flex-grow">
        <Typography variant="h6" color="blue-gray">
          {title}
        </Typography>
        <Typography variant="small" color="gray" className="font-normal">
          {description}
        </Typography>
      </div>
      {popular && (
        <span className="bg-yellow-100 text-yellow-800 text-xs font-medium px-2.5 py-0.5 rounded">
          Le plus utilisé
        </span>
      )}
    </CardBody>
  </Card>
);

export default function PaymentModal({ isOpen, onClose, paymentLink }) {
  const [isTransferPopupOpen, setIsTransferPopupOpen] = useState(false);

  const handlePayment = (method) => {
    if (method === "transfer") {
      setIsTransferPopupOpen(true);
      onClose();
    } else if (paymentLink) {
      window.open(paymentLink, '_blank');
      onClose();
    }
  };

  const closeTransferPopup = () => {
    setIsTransferPopupOpen(false);
  };

  return (
    <>
      <Dialog open={isOpen} handler={onClose} size="sm">
        <DialogHeader>
          <Typography variant="h5" color="blue-gray">
            Choisissez votre moyen de paiement
          </Typography>
        </DialogHeader>
        <DialogBody divider className="overflow-y-auto max-h-[60vh]">
          <PaymentOption
            icon={<CreditCardIcon className="h-6 w-6 text-blue-500" />}
            title="Carte bancaire"
            description="Payez par carte bancaire. Service fourni par Stripe. Votre rapport sera disponible sous 30 secondes*"
            onClick={() => handlePayment("card")}
          />
          <PaymentOption
            icon={<ArrowPathIcon className="h-6 w-6 text-green-500" />}
            title="E-Virement"
            description="Un virement classique en mieux ! La transaction est directement rapprochée et votre rapport sera disponible sous 30 secondes*"
            popular={true}
            onClick={() => handlePayment("e-transfer")}
          />
          <PaymentOption
            icon={<BanknotesIcon className="h-6 w-6 text-gray-500" />}
            title="Virement"
            description="Le virement classique. Sa gestion prend plus de temps que les autres solutions. Votre rapport sera disponible dès traitement par nos équipes."
            onClick={() => handlePayment("transfer")}
          />
          <Typography variant="small" color="gray" className="text-xs mt-4 italic">
            *Sous réserve de la finalisation par nos équipes ou absence d'élément(s) manquant(s) du donneur d'ordre.
          </Typography>
        </DialogBody>
        <div className="flex justify-end gap-2 p-4">
          <Button variant="outlined" onClick={onClose}>
            Annuler
          </Button>
        </div>
      </Dialog>

      <Dialog 
        open={isTransferPopupOpen} 
        handler={closeTransferPopup} 
        size="sm"
      >
        <DialogHeader>
          <Typography variant="h5" color="blue-gray">
            Important : Référence de paiement
          </Typography>
        </DialogHeader>
        <DialogBody>
          <Typography color="gray" className="font-normal mb-4">
            Sur la page suivante, une référence de paiement unique vous sera fournie. Vous devez la recopier exactement dans le libellé de votre virement, sans ajout d'informations supplémentaires (nom, numéro de facture, etc.).
          </Typography>
          <Typography color="gray" className="font-normal">
            Sans cette référence exacte, le traitement de votre paiement devra être effectué manuellement, ce qui peut prendre plusieurs jours.
          </Typography>
        </DialogBody>
        <div className="flex justify-between gap-2 p-4">
          <Button 
            variant="outlined"
            onClick={closeTransferPopup}
          >
            Retour
          </Button>
          <Button 
            className="bg-[#023634] hover:bg-[#042C2B]"
            onClick={() => {
              closeTransferPopup();
              if (paymentLink) {
                window.open(paymentLink, '_blank');
              }
            }}
          >
            Continuer vers le virement
          </Button>
        </div>
      </Dialog>
    </>
  );
}