import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  Typography,
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input
} from "@material-tailwind/react";
import { Helmet } from "react-helmet";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import Layout from "../components/layout/Layout";
import PropertySelector from "../components/PropertySelector";
import { useProperties } from "../contexts/PropertiesContext";
import { colors } from "../utils/colors";
import axiosInstance from "../utils/axiosInstance";
import SignatureCanvas from "react-signature-canvas";

/**
 * Ce composant gère deux cas :
 * 1) isOrganization = false
 *    - Affiche le formulaire de consentement DPE (avec signature, choix Oui / Non).
 * 2) isOrganization = true
 *    - Affiche un texte explicatif et un formulaire pour renseigner les coordonnées du client
 *      (Nom / Prénom en full largeur, Email / Téléphone en demi-largeur),
 *      puis envoi d'une requête POST à /api/ask-dpe-consent/:id.
 */
export default function DPE() {
  const [isMobile, setIsMobile] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const { properties, loading, refreshProperties } = useProperties();

  // Informations sur la propriété sélectionnée
  const [selectedProperty, setSelectedProperty] = useState(null);

  // Indique si l'utilisateur est une organisation ou non
  const [isOrganization, setIsOrganization] = useState(false);

  // État qui gère le chargement des infos de la personne
  const [isPersonInfoLoading, setIsPersonInfoLoading] = useState(true);
  const [isPropertySelectionDone, setIsPropertySelectionDone] = useState(false);

  // Stockage des infos renvoyées par l'API person_info
  const [personInfo, setPersonInfo] = useState({
    name: "",
    email: "",
    phone: ""
  });

  // États pour la version "particulier" (isOrganization = false)
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  // Signature pour la version "particulier"
  const signatureRef = useRef();

  // Infos personnelles pour la confirmation modale (quand on consent = "oui")
  const [personalInfo, setPersonalInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: ""
  });

  // États pour la version "organisation"
  const [orgError, setOrgError] = useState("");
  const [orgSuccess, setOrgSuccess] = useState(false);

  // PDF DPE
  const pdfUrl = "https://client.dimo-diagnostic.net/dpe.pdf";

  // Gère la détection du mode mobile
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  // Une fois que les propriétés sont chargées, on détermine la propriété sélectionnée
  useEffect(() => {
    if (!loading && properties.length > 0) {
      if (id) {
        const property = properties.find((p) => p.id.toString() === id);
        if (property && (property.actionsNeeded.dpe || success || orgSuccess)) {
          setIsPersonInfoLoading(true);
          setSelectedProperty(property);
        } else {
          navigate("/dpe", { replace: true });
        }
      } else {
        const propertyNeedingDPE = properties.find((p) => p.actionsNeeded.dpe);
        if (propertyNeedingDPE) {
          setIsPersonInfoLoading(true);
          navigate(`/dpe/${propertyNeedingDPE.id}`, { replace: true });
        } else {
          setSelectedProperty(null);
        }
      }
      setIsPropertySelectionDone(true);
    } 
    else if (!loading && properties.length === 0) {
      setIsPropertySelectionDone(true);
    }
  }, [id, properties, loading, navigate, success, orgSuccess]);
  
  // On va chercher les infos de la personne liée à la propriété (pour savoir si c'est une orga ou un particulier)
  useEffect(() => {
    const fetchPersonInfo = async () => {
      if (selectedProperty) {
        setIsPersonInfoLoading(true); // on démarre le loading
        try {
          const res = await axiosInstance.get(`/person_info/${selectedProperty.id}`);
          setIsOrganization(res.data.logged_as_organization ?? false);
          setPersonInfo({
            name: res.data.person?.name || "",
            email: res.data.person?.email || "",
            phone: res.data.person?.phone || ""
          });
        } catch (err) {
          console.error("Erreur lors de la récupération des infos de la personne:", err);
        } finally {
          // Une fois la requête terminée (réussie ou échouée), on arrête le loading
          setIsPersonInfoLoading(false);
        }
      } else {
        // Si pas de propriété sélectionnée, on peut aussi arrêter le loading
        setIsPersonInfoLoading(false);
      }
    };

    fetchPersonInfo();
  }, [selectedProperty]);

  // Liste des propriétés nécessitant un DPE
  const propertiesNeedingDPE = properties.filter((p) => p.actionsNeeded.dpe);

  const handlePropertyChange = (newId) => {
    const property = properties.find((p) => p.id.toString() === newId.toString());
    setSelectedProperty(property);
    navigate(`/dpe/${newId}`, { replace: true });
  };

  /**
   * ----------------------------
   * Partie "Particulier"
   * ----------------------------
   */
  const handleSubmit = async (choice) => {
    if (signatureRef.current.isEmpty()) {
      setError("Veuillez signer le formulaire avant de soumettre votre choix.");
      return;
    }

    // Si on clique sur "Oui", on ouvre la modal de confirmation
    if (choice === "oui") {
      setShowConfirmModal(true);
    } else {
      // Sinon, on soumet directement (choice = "non")
      await submitConsent(choice);
    }
  };

  // On met à jour les infos personnelles dans la confirmation modale
  const handlePersonalInfoChange = (e) => {
    setPersonalInfo({
      ...personalInfo,
      [e.target.name]: e.target.value
    });
  };

  // Vérifie que toutes les infos personnelles sont renseignées
  const validatePersonalInfo = () => {
    return Object.values(personalInfo).every((value) => value.trim() !== "");
  };

  // Soumission du consentement vers l'API
  const submitConsent = async (choice) => {
    if (choice === "oui" && !validatePersonalInfo()) {
      setError("Veuillez remplir tous les champs du formulaire.");
      return;
    }

    setIsSubmitting(true);
    setError("");

    try {
      const signatureData = signatureRef.current.toDataURL();
      const response = await axiosInstance.post("/dpe-consent", {
        consent: choice,
        signature: signatureData,
        propertyId: selectedProperty?.id,
        personalInfo: choice === "oui" ? personalInfo : null
      });
      if (response.data.success) {
        setSuccess(true); // D'abord on met le succès
        setTimeout(() => refreshProperties(), 500); // Puis on rafraîchit les propriétés avec un délai
      } else {
        throw new Error("Échec de l'enregistrement du consentement.");
      }
    } catch (error) {
      console.error("Erreur lors de l'enregistrement du consentement:", error);
      setError("Une erreur s'est produite. Veuillez réessayer.");
    } finally {
      setIsSubmitting(false);
      setShowConfirmModal(false);
    }
  };

  // Permet de nettoyer la signature
  const clearSignature = () => {
    signatureRef.current.clear();
  };

  /**
   * ----------------------------
   * Partie "Organisation"
   * ----------------------------
   */
  const handleOrgSubmit = async () => {
    setOrgError("");
    setOrgSuccess(false);

    try {
      const response = await axiosInstance.post(`/ask-dpe-consent/${selectedProperty?.id}`, {
        name: personInfo.name,
        email: personInfo.email,
        phone: personInfo.phone
      });

      if (response.data.success) {
        setOrgSuccess(true); // D'abord on met le succès
        setTimeout(() => refreshProperties(), 500); // Puis on rafraîchit les propriétés avec un délai
      } else {
        setOrgError(response.data.error || "Une erreur s'est produite.");
      }
    } catch (err) {
      console.error("Erreur lors de l'envoi des données DPE (orga):", err);
      setOrgError("Une erreur s'est produite. Veuillez réessayer.");
    }
  };

  /**
   * Affichage du PDF (iframe ou object selon la taille d'écran)
   */
  const renderPdfViewer = () => (
    <div className="w-full h-full" style={{ aspectRatio: "1 / 1.4142" }}>
      {isMobile ? (
        <iframe
          src={`https://docs.google.com/viewer?url=${encodeURIComponent(pdfUrl)}&embedded=true`}
          className="w-full border-0 h-full"
          title="DPE Consent Document"
        />
      ) : (
        <object
          data={pdfUrl}
          type="application/pdf"
          className="w-full h-full border-0"
        >
          <iframe
            src={`https://docs.google.com/viewer?url=${encodeURIComponent(pdfUrl)}&embedded=true`}
            className="w-full h-full border-0"
            title="DPE Consent Document"
          />
        </object>
      )}
    </div>
  );

  // Ajout du composant Skeleton
  const DPESkeleton = () => {
    const isMobile = window.innerWidth <= 768;
    
    return (
      <div className={`flex ${isMobile ? "flex-col" : "flex-row"} gap-6 animate-pulse`}>
        {/* Partie gauche */}
        <div className={`${isMobile ? "w-full" : "w-1/2"} mb-6`}>
          <Card className="shadow-md p-6 space-y-6">
            {/* Skeleton pour le titre */}
            <div className="h-8 bg-gray-200 rounded-lg w-3/4"></div>
            
            {/* Skeletons pour les paragraphes */}
            <div className="space-y-4">
              {[...Array(4)].map((_, index) => (
                <div key={index} className="space-y-2">
                  <div className="h-4 bg-gray-200 rounded w-full"></div>
                  <div className="h-4 bg-gray-200 rounded w-5/6"></div>
                </div>
              ))}
            </div>

            {/* Skeleton pour la zone de signature */}
            <div className="mt-6">
              <div className="h-6 bg-gray-200 rounded w-1/4 mb-2"></div>
              <div className="h-40 bg-gray-200 rounded w-full"></div>
            </div>

            {/* Skeleton pour les boutons */}
            <div className="flex justify-center space-x-4 mt-6">
              <div className="h-10 bg-gray-200 rounded w-32"></div>
              <div className="h-10 bg-gray-200 rounded w-32"></div>
            </div>
          </Card>
        </div>

        {/* Partie droite (PDF) */}
        <div className={`${isMobile ? "w-full" : "w-1/2"} mb-6`}>
          <Card 
            className="shadow-md overflow-hidden w-full bg-gray-200"
            style={{
              height: isMobile ? "500px" : "calc(100vh - 100px)",
              maxWidth: isMobile ? "100%" : "calc((100vh - 100px) / 1.4142)"
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <Layout>
      <Helmet>
        <title>Formulaire de consentement DPE - Dimo Diagnostic</title>
        <meta
          name="description"
          content="Signez le formulaire de consentement pour le Diagnostic de Performance Énergétique (DPE)."
        />
        <link
          rel="canonical"
          href={`https://client.dimo-diagnostic.net/dpe${id ? `/${id}` : ""}`}
        />
      </Helmet>

    {/*
      1) On gère le `Spinner` global si on load encore la liste des propriétés
         OU la requête person_info
         OU la sélection de propriété (isPropertySelectionDone === false)
    */}
    {(loading || isPersonInfoLoading || !isPropertySelectionDone) && (
      <DPESkeleton />
    )}

    {/*
      2) Si on a fini de charger (loading = false, isPersonInfoLoading = false, 
         isPropertySelectionDone = true) ET qu'on n'a pas de selectedProperty
         => on affiche "pas de propriété"
    */}
    {!loading && !isPersonInfoLoading && isPropertySelectionDone && !selectedProperty && (
      <Card className="shadow-md p-6 mb-6 space-y-6 w-full">
        <Typography variant="h4" color="blue-gray" className="text-center">
          Formulaire de consentement DPE
        </Typography>
        <Typography variant="paragraph" color="blue-gray" className="text-center">
          Vous n'avez actuellement aucun dossier nécessitant un accord DPE.
        </Typography>
        <Typography variant="paragraph" color="blue-gray" className="text-center">
          Si vous pensez qu'il s'agit d'une erreur, veuillez contacter notre support.
        </Typography>
        <div className="flex justify-center">
          <Button
            variant="filled"
            style={{ backgroundColor: colors.dimoPrimary, width: "fit-content" }}
            onClick={() => navigate("/")}
          >
            Retour à l'accueil
          </Button>
        </div>
      </Card>
    )}

      {/* Cas 2 : On a bien une propriété sélectionnée (et on a fini de charger les infos) */}
      {!loading && !isPersonInfoLoading && isPropertySelectionDone && selectedProperty && (
        <div className={`flex ${isMobile ? "flex-col" : "flex-row"} gap-6`}>
          {/* -- PARTIE GAUCHE -- */}
          <div className={`${isMobile ? "w-full" : "w-1/2"} mb-6`}>
            <Card className="shadow-md p-6 space-y-6">
              {/* Sélecteur de propriétés si plusieurs en ont besoin */}
              {propertiesNeedingDPE.length > 1 && (
                <PropertySelector
                  selectedProperty={selectedProperty ? selectedProperty.id : null}
                  setSelectedProperty={handlePropertyChange}
                  properties={propertiesNeedingDPE}
                  isLoading={loading}
                />
              )}

              {/* Si isOrganization === true => on affiche un texte spécial + un formulaire pour les coordonnées */}
              {isOrganization ? (
                <>
                  <Typography variant="h4" color="blue-gray" className="mb-4">
                    Demande de consentement DPE pour un client
                  </Typography>
                  {orgSuccess ? (
                    <div className="flex flex-col items-center justify-center space-y-6">
                      <CheckCircleIcon className="h-16 w-16 text-green-500" />
                      <Typography variant="h5" color="green">
                        Les informations ont bien été envoyées !
                      </Typography>
                      <Typography color="blue-gray" className="text-center">
                        Vous pouvez revenir à votre espace si besoin.
                      </Typography>
                      <Button
                        variant="filled"
                        style={{ backgroundColor: colors.dimoPrimary }}
                        onClick={() =>
                          navigate(`/diagnostics/${selectedProperty.id}`)
                        }
                      >
                        Retour au diagnostic
                      </Button>
                    </div>
                  ) : (
                    <>
                      <Typography variant="paragraph" color="blue-gray" className="mb-4">
                        Dans le cadre de la réalisation du Diagnostic de Performance
                        Énergétique (DPE) de votre client, nous devons recueillir son choix
                        concernant la transmission de ses données personnelles (nom, prénom,
                        e-mail, téléphone) à l'ADEME.
                      </Typography>
                      <Typography variant="paragraph" color="blue-gray" className="mb-4">
                        Pour cela, nous avons besoin de recueillir ses coordonnées afin de
                        lui adresser cette requête.
                      </Typography>
                      <Typography variant="paragraph" color="blue-gray" className="mb-4">
                        Les coordonnées que vous saisirez, si nous ne les possédons pas
                        déjà, seront exclusivement destinées à la signature de ce
                        consentement.
                      </Typography>
                      <Typography variant="paragraph" color="blue-gray" className="mb-4">
                        Sa décision n'aura aucune incidence sur la réalisation, les
                        résultats ou la validité du DPE.
                      </Typography>
                      <Typography variant="paragraph" color="blue-gray" className="mb-4">
                        S'il accepte, il pourrait être contacté par nos organismes de
                        certification pour d'éventuelles vérifications des compétences des
                        diagnostiqueurs, conformément à la réglementation.
                      </Typography>
                      <Typography variant="paragraph" color="blue-gray" className="mb-4">
                        S'il refuse, ses données resteront strictement confidentielles et
                        ne seront pas partagées avec l'ADEME ni avec le centre de
                        certification de l'opérateur ayant réalisé le DPE.
                      </Typography>

                      {/* Formulaire Nom / Prénom en full width, Email / Téléphone en half width */}
                      <div className="flex flex-col gap-6 mt-4">
                        <div>
                          <Input
                            type="text"
                            label="Nom et prénom de votre client"
                            value={personInfo.name}
                            onChange={(e) =>
                              setPersonInfo({ ...personInfo, name: e.target.value })
                            }
                            required
                            className="mb-4"
                          />
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                          <Input
                            type="email"
                            label="Email de votre client"
                            value={personInfo.email}
                            onChange={(e) =>
                              setPersonInfo({ ...personInfo, email: e.target.value })
                            }
                            required
                          />
                          <Input
                            type="tel"
                            label="Téléphone de votre client"
                            value={personInfo.phone}
                            onChange={(e) =>
                              setPersonInfo({ ...personInfo, phone: e.target.value })
                            }
                            required
                          />
                        </div>
                      </div>

                      {orgError && (
                        <Typography color="red" className="mt-4 text-center">
                          {orgError}
                        </Typography>
                      )}

                      <div className="flex justify-center mt-8">
                        <Button
                          variant="filled"
                          style={{ backgroundColor: colors.dimoPrimary }}
                          onClick={handleOrgSubmit}
                        >
                          Envoyer la demande de consentement à votre client
                        </Button>
                      </div>
                    </>
                  )}
                </>
              ) : (
                /**
                 * -----------------------------
                 * Partie pour un particulier
                 * -----------------------------
                 */
                <>
                  {success ? (
                    <div className="flex flex-col items-center justify-center space-y-6">
                      <CheckCircleIcon className="h-16 w-16 text-green-500" />
                      <Typography variant="h4" color="green">
                        Merci pour votre réponse !
                      </Typography>
                      <Typography color="blue-gray" className="text-center">
                        Votre choix a bien été enregistré.
                        Vous pouvez maintenant revenir à votre diagnostic.
                      </Typography>
                      <Button
                        variant="filled"
                        style={{ backgroundColor: colors.dimoPrimary }}
                        onClick={() =>
                          navigate(`/diagnostics/${selectedProperty.id}`)
                        }
                      >
                        Retour au diagnostic
                      </Button>
                    </div>
                  ) : (
                    <>
                      <Typography variant="h4" color="blue-gray" className="mb-4">
                        Formulaire de consentement DPE
                      </Typography>
                      <Typography
                        variant="paragraph"
                        color="blue-gray"
                        className="mb-4"
                      >
                        Dans le cadre de la réalisation de votre Diagnostic de
                        Performance Énergétique (DPE), nous devons recueillir votre choix
                        concernant la transmission de vos données personnelles (nom,
                        prénom, e-mail, téléphone) à l'ADEME.
                      </Typography>
                      <Typography
                        variant="paragraph"
                        color="blue-gray"
                        className="mb-4"
                      >
                        <strong>Oui, je consens :</strong> vos données seront transmises
                        à l'ADEME pour d'éventuelles vérifications des compétences des
                        diagnostiqueurs, conformément à la réglementation.
                      </Typography>
                      <Typography
                        variant="paragraph"
                        color="blue-gray"
                        className="mb-4"
                      >
                        <strong>Non, je refuse :</strong> vos données resteront
                        strictement confidentielles et ne seront pas partagées.
                      </Typography>
                      <Typography variant="paragraph" color="blue-gray">
                        Votre décision n'aura aucune incidence sur la réalisation ou la
                        validité de votre DPE. Merci de compléter et signer le formulaire
                        pour valider votre choix.
                      </Typography>

                      {/* Zone de signature */}
                      <div className="mb-6 flex flex-col items-center">
                        <Typography
                          variant="h6"
                          color="blue-gray"
                          className="mb-2"
                        >
                          Signez ici :
                        </Typography>
                        <div className="border border-gray-300 rounded-md w-full md:w-3/4">
                          <SignatureCanvas
                            ref={signatureRef}
                            canvasProps={{
                              className: "signature-canvas w-full",
                              style: { aspectRatio: "2/1" }
                            }}
                          />
                        </div>
                        <Button
                          size="sm"
                          variant="text"
                          color="blue-gray"
                          className="mt-2"
                          onClick={clearSignature}
                        >
                          Effacer la signature
                        </Button>
                      </div>

                      {/* Boutons de consentement */}
                      <div className="flex justify-center space-x-4">
                        <Button
                          variant="outlined"
                          color="blue-gray"
                          onClick={() => handleSubmit("oui")}
                          disabled={isSubmitting}
                        >
                          Oui, je consens
                        </Button>
                        <Button
                          style={{ backgroundColor: colors.dimoPrimary }}
                          onClick={() => handleSubmit("non")}
                          disabled={isSubmitting}
                        >
                          Non, je refuse
                        </Button>
                      </div>

                      {error && (
                        <Typography
                          color="red"
                          className="mt-4 text-center"
                        >
                          {error}
                        </Typography>
                      )}
                    </>
                  )}
                </>
              )}
            </Card>
          </div>

          {/* -- PARTIE DROITE (PDF) -- */}
          <div
            className={`${
              isMobile ? "w-full" : "w-1/2"
            } flex justify-center items-center mb-6`}
          >
            <Card
              className="shadow-md overflow-hidden w-full"
              style={{
                height: isMobile ? "500px" : "calc(100vh - 100px)",
                maxWidth: isMobile ? "100%" : "calc((100vh - 100px) / 1.4142)"
              }}
            >
              {renderPdfViewer()}
            </Card>
          </div>
        </div>
      )}

      {/* MODALE de confirmation (uniquement pour le particulier qui clique sur "Oui") */}
      <Dialog open={showConfirmModal} handler={() => setShowConfirmModal(false)} size="md">
        <DialogHeader>Confirmation de votre choix</DialogHeader>
        <DialogBody divider>
          <Typography color="blue-gray" className="mb-4">
            En choisissant "Oui", vous acceptez que vos données soient transmises à l'ADEME.
            Dans le cadre d'un contrôle de compétence du diagnostiqueur, il est possible que
            vous soyez amené à devoir effectuer une nouvelle visite dans votre bien.
          </Typography>
          <Typography color="blue-gray" className="font-semibold mb-4">
            Veuillez confirmer vos informations personnelles :
          </Typography>
          <div className="grid grid-cols-2 gap-4">
            <Input
              type="text"
              label="Prénom"
              name="firstName"
              value={personalInfo.firstName}
              onChange={handlePersonalInfoChange}
              required
            />
            <Input
              type="text"
              label="Nom"
              name="lastName"
              value={personalInfo.lastName}
              onChange={handlePersonalInfoChange}
              required
            />
            <Input
              type="email"
              label="Email"
              name="email"
              value={personalInfo.email}
              onChange={handlePersonalInfoChange}
              required
            />
            <Input
              type="tel"
              label="Téléphone"
              name="phone"
              value={personalInfo.phone}
              onChange={handlePersonalInfoChange}
              required
            />
          </div>
          {!validatePersonalInfo() && (
            <Typography color="red" className="mt-4">
              Veuillez remplir tous les champs avant de confirmer.
            </Typography>
          )}
          <Typography color="blue-gray" className="font-semibold mt-4">
            Êtes-vous sûr de vouloir partager vos coordonnées ? Sachez que vous pouvez
            refuser, cela n'influe pas sur votre diagnostic.
          </Typography>
        </DialogBody>
        <DialogFooter className="space-x-2">
          <Button
            variant="outlined"
            style={{ borderColor: colors.dimoPrimary, color: colors.dimoPrimary }}
            onClick={() => submitConsent("oui")}
            disabled={!validatePersonalInfo()}
          >
            Je confirme mon choix
          </Button>
          <Button
            style={{ backgroundColor: colors.dimoPrimary }}
            onClick={() => {
              setShowConfirmModal(false);
              submitConsent("non");
            }}
          >
            Je change d'avis et je refuse
          </Button>
        </DialogFooter>
      </Dialog>
    </Layout>
  );
}
