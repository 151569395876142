import React, { useState, useEffect } from 'react';
import { Card, Typography, Button, Spinner, Dialog, DialogHeader, DialogBody } from "@material-tailwind/react";
import Layout from "../components/layout/Layout";
import { colors } from "../utils/colors";
import { Helmet } from 'react-helmet';
import { GiftIcon } from "@heroicons/react/24/outline";
import axiosInstance from "../utils/axiosInstance";

export default function Deals() {
  const [deals, setDeals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [selectedDeal, setSelectedDeal] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('all');

  const categories = [
    { id: 'all', label: 'Tous' },
    { id: 'immobilier', label: 'Immobilier' },
    { id: 'banque', label: 'Banque' },
    { id: 'energie', label: 'Énergie' },
    { id: 'services', label: 'Services' },
    { id: 'assurance', label: 'Assurance' }
  ];

  useEffect(() => {
    const fetchDeals = async () => {
      try {
        const response = await axiosInstance.get('/deals');
        setDeals(response.data.deals);
        setLoading(false);
      } catch (err) {
        console.error('Erreur lors de la récupération des bons plans:', err);
        setError('Une erreur est survenue lors du chargement des bons plans.');
        setLoading(false);
      }
    };

    fetchDeals();
  }, []);

  const handleDealRequest = (deal) => {
    setSelectedDeal(deal);
    setOpenConfirmModal(true);
  };

  const handleConfirm = async () => {
    if (selectedDeal?.redirect_url) {
      try {
        // Log le clic avant d'ouvrir le lien
        await axiosInstance.post('/deals/log-click', {
          dealId: selectedDeal.id,
          provider: selectedDeal.provider,
          category: selectedDeal.category
        });
        
        // Ouvre le lien dans un nouvel onglet
        window.open(selectedDeal.redirect_url, '_blank');
      } catch (error) {
        console.error('Erreur lors de l\'enregistrement du clic:', error);
        // On ouvre quand même le lien même si le log échoue
        window.open(selectedDeal.redirect_url, '_blank');
      }
    }
    setOpenConfirmModal(false);
    setSelectedDeal(null);
  };

  const handleCloseModal = () => {
    setOpenConfirmModal(false);
    setSelectedDeal(null);
  };

  const filteredDeals = deals.filter(deal => 
    selectedCategory === 'all' || deal.category === selectedCategory
  );

  if (loading) {
    return (
      <Layout>
        <div className="flex justify-center items-center h-64">
          <Spinner className="h-12 w-12" style={{ color: colors.dimoPrimary }} />
        </div>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <Card className="p-6">
          <Typography color="red" className="text-center">{error}</Typography>
        </Card>
      </Layout>
    );
  }

  return (
    <Layout>
      <Helmet>
        <title>Bons plans - Dimo Diagnostic</title>
        <meta name="description" content="Découvrez nos offres spéciales et bons plans pour vos diagnostics immobiliers" />
        <link rel="canonical" href="https://client.dimo-diagnostic.net/deals" />
      </Helmet>

      <Card className="mb-6 shadow-md">
        <div className="p-6">
          <div className="flex items-center gap-4 mb-6">
            <GiftIcon className="h-8 w-8" style={{ color: colors.dimoPrimary }} />
            <div>
              <Typography variant="h3" className="text-black">Bons plans</Typography>
              <Typography variant="paragraph" className="text-gray-700">
                Imaginé par nos clients, pour nos clients !
              </Typography>
            </div>
          </div>

          <Typography variant="paragraph" className="mb-8 text-gray-700">
            Nous avons pris soin de dénicher les services les plus pertinents du moment, spécialement conçues pour vous accompagner dans vos projets avec sérénité, efficacité et intelligence financière.
            Pensés pour répondre à vos attentes, ces services sont là pour vous simplifier la vie et enrichir chaque étape de votre expérience avec nous.
            Vous êtes à un clic d'améliorer votre projet ! 👇
          </Typography>

          {/* Section Deals Recommandés */}
          <div className="mb-12">
            <Typography variant="h5" className="text-black mb-6">
              Bons plans repérés
            </Typography>
            <div className="relative">
              <div className="flex gap-4 overflow-x-auto pb-2 -mx-4 px-4 md:gap-6 md:-mx-6 md:px-6 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent">
                {deals.filter(d => d.recommended).map((deal) => (
                  <Card 
                    key={deal.id} 
                    className="flex-shrink-0 w-[260px] md:w-[280px] bg-white hover:shadow-lg transition-all duration-300 overflow-hidden cursor-pointer"
                    onClick={() => handleDealRequest(deal)}
                  >
                    <div className="relative">
                      {/* Image principale */}
                      <div className="aspect-[4/3] bg-gray-50">
                        <img 
                          src={deal.image || '/placeholder.png'} 
                          alt={deal.title} 
                          className="w-full h-full object-cover"
                        />
                      </div>
                      {/* Logo en bas à gauche */}
                      <div className="absolute bottom-2 left-2 bg-white rounded-lg p-2 shadow-sm">
                        <img 
                          src={deal.logo || '/placeholder-logo.png'} 
                          alt={`${deal.title} logo`} 
                          className="w-8 h-8 object-contain"
                        />
                      </div>
                    </div>
                    <div className="p-4">
                      <div className="flex items-center gap-2 mb-2">
                        <Typography variant="small" className="font-bold">
                          {deal.provider}
                        </Typography>
                      </div>
                      <Typography variant="paragraph" className="text-sm text-gray-700">
                        {deal.shortDescription || deal.description[0]}
                      </Typography>
                    </div>
                  </Card>
                ))}
              </div>
            </div>
          </div>

          {/* Toutes les intégrations */}
          <div>
            <div className="flex flex-col gap-4 mb-8">
              <Typography variant="h4" className="text-black">
                Tous les bons plans
              </Typography>
              <div className="flex flex-wrap gap-2">
                {categories.map(category => (
                  <Button 
                    key={category.id}
                    variant={selectedCategory === category.id ? "filled" : "outlined"}
                    size="sm"
                    className="normal-case rounded-full"
                    style={selectedCategory === category.id ? 
                      { backgroundColor: colors.dimoPrimary } : 
                      { borderColor: colors.dimoPrimary, color: colors.dimoPrimary }
                    }
                    onClick={() => setSelectedCategory(category.id)}
                  >
                    {category.label}
                  </Button>
                ))}
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {filteredDeals.map((deal) => (
                <Card 
                  key={deal.id} 
                  className="flex flex-col bg-white shadow-md hover:shadow-lg transition-all duration-300 overflow-hidden cursor-pointer"
                  onClick={() => handleDealRequest(deal)}
                >
                  <div className="h-48 bg-gray-50 overflow-hidden">
                    <img 
                      src={deal.image} 
                      alt={deal.title} 
                      className="w-full h-full object-cover"
                    />
                  </div>

                  <div className="p-4 flex flex-col flex-grow">
                    <div className="flex items-center gap-2 mb-2">
                      <img 
                        src={deal.logo} 
                        alt={`${deal.provider} logo`} 
                        className="w-6 h-6 object-contain"
                      />
                      <Typography variant="small" className="font-bold text-gray-800">
                        {deal.provider}
                      </Typography>
                    </div>
                    <Typography variant="h6" className="font-bold mb-1">
                      {deal.title}
                    </Typography>
                    <Typography className="text-gray-600 text-sm mb-3">
                      {deal.subtitle}
                    </Typography>

                    <ul className="list-disc pl-4 mb-4 flex-grow text-sm">
                      {deal.description.map((item, index) => (
                        <li key={index} className="text-gray-600 mb-1">
                          {item}
                        </li>
                      ))}
                    </ul>

                    <Button
                      fullWidth
                      className="mt-auto normal-case text-sm"
                      style={{ backgroundColor: colors.dimoPrimary, color: colors.dimoPop }}
                    >
                      Profitez de l'offre
                    </Button>
                  </div>
                </Card>
              ))}
            </div>
          </div>
        </div>
      </Card>

      {/* Modal de confirmation */}
      <Dialog open={openConfirmModal} handler={handleCloseModal} size="sm">
        <DialogHeader className="justify-center">
          <Typography variant="h5" className="text-center">
            🎉 Super choix !
          </Typography>
        </DialogHeader>
        <DialogBody className="text-center">
          {selectedDeal?.id === 'qoridor' ? (
            <>
              <Typography className="mb-4 font-medium text-lg text-gray-800">
                Vous allez être recontacté par Qoridor
              </Typography>
              <Typography className="mb-6 text-gray-600">
                Qoridor dispose des outils les plus avancés sur le marché pour estimer votre bien. C'est le moment d'en profiter et tout cela gratuitement !
              </Typography>
              <div className="flex gap-4 justify-center mb-6">
                <Button
                  variant="outlined"
                  onClick={handleCloseModal}
                  style={{ borderColor: colors.dimoPrimary, color: colors.dimoPrimary }}
                >
                  Plus tard
                </Button>
                <Button
                  onClick={handleConfirm}
                  style={{ backgroundColor: colors.dimoPrimary }}
                >
                  Découvrir l'offre
                </Button>
              </div>
              <Typography variant="small" className="text-gray-500 text-xs">
                En cliquant sur "Découvrir l'offre" vous reconnaissez que vos données personnelles seront traitées conformément à la{' '}
                <a 
                  href="https://www.dimo-diagnostic.net/protection-des-donnees" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:text-blue-700"
                  onClick={(e) => e.stopPropagation()}
                >
                  politique de confidentialité de DIMO DIAGNOSTIC
                </a>
                .
              </Typography>
            </>
          ) : (
            <>
              <Typography className="mb-4 font-medium text-lg text-gray-800">
                Vous allez être redirigé vers {selectedDeal?.provider}
              </Typography>
              <Typography className="mb-6 text-gray-600">
                Nous avons sélectionné la meilleure offre du marché pour vous. 
                C'est le moment d'en profiter !
              </Typography>
              <div className="flex gap-4 justify-center">
                <Button
                  variant="outlined"
                  onClick={handleCloseModal}
                  style={{ borderColor: colors.dimoPrimary, color: colors.dimoPrimary }}
                >
                  Plus tard
                </Button>
                <Button
                  onClick={handleConfirm}
                  style={{ backgroundColor: colors.dimoPrimary }}
                >
                  Découvrir l'offre
                </Button>
              </div>
            </>
          )}
        </DialogBody>
      </Dialog>
    </Layout>
  );
} 