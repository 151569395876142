import React, { useState, useEffect } from "react";
import { Card, Typography, Button, Rating } from "@material-tailwind/react";
import { Helmet } from "react-helmet";
import Layout from "../components/layout/Layout";
import { colors } from "../utils/colors";

export default function Reviews() {
  const [hoveredVideo, setHoveredVideo] = useState(null);

  const videoPlaceholders = [
    {
      id: 1,
      title: "Diagnostic de mon appartement",
      author: "Marie L.",
      preview: "bg-gradient-to-br from-blue-100 to-blue-200"
    },
    {
      id: 2,
      title: "Super professionnel",
      author: "Thomas D.",
      preview: "bg-gradient-to-br from-green-100 to-green-200"
    },
    {
      id: 3,
      title: "Rapide et efficace",
      author: "Sophie M.",
      preview: "bg-gradient-to-br from-purple-100 to-purple-200"
    }
  ];

  useEffect(() => {
    // Script Trustpilot
    const trustpilotScript = document.createElement("script");
    trustpilotScript.type = "text/javascript";
    trustpilotScript.src = "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
    trustpilotScript.async = true;
    document.head.appendChild(trustpilotScript);

    // Script Immodvisor
    const immodvisorScript = document.createElement("script");
    immodvisorScript.id = "imdw-js-global";
    immodvisorScript.setAttribute("data-launch-mode", "onload");
    immodvisorScript.src = "https://widget3.immodvisor.com/javascripts/imdw-global.js";
    document.body.appendChild(immodvisorScript);

    // Rich snippets Immodvisor
    fetch(
      "https://api-reviews.immodvisor.com/api/company/richs_snippets?id=68883&key=FPDMS-E1RI-OOVUY-BPHK-GG8RD9"
    )
      .then((response) => response.text())
      .then((structuredDataText) => {
        const script = document.createElement("script");
        script.setAttribute("type", "application/ld+json");
        script.textContent = structuredDataText;
        document.head.appendChild(script);
      });

    return () => {
      document.body.removeChild(immodvisorScript);
      document.head.removeChild(trustpilotScript);
      const ldJsonScript = document.querySelector('script[type="application/ld+json"]');
      if (ldJsonScript) {
        document.head.removeChild(ldJsonScript);
      }
    };
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>Expériences et avis - Dimo Diagnostic</title>
        <meta
          name="description"
          content="Plus de 5800 clients satisfaits ont partagé leur expérience avec Dimo Diagnostic. Découvrez leurs avis authentiques."
        />
      </Helmet>

      {/* Première section avec vidéo et Trustpilot */}
      <Card className="p-6 shadow-md mb-6 bg-white">
        <div className="flex flex-col lg:flex-row gap-8 items-center">
          <div className="lg:w-1/2 space-y-6">
            <div className="flex items-center gap-2 mb-2">
              <Rating value={5} readonly>
                <Rating.Star className="text-yellow-700" />
                <Rating.Star className="text-yellow-700" />
                <Rating.Star className="text-yellow-700" />
                <Rating.Star className="text-yellow-700" />
                <Rating.Star className="text-yellow-700" />
              </Rating>
              <Typography className="font-semibold text-gray-800">
                4.6/5 sur Trustpilot
              </Typography>
            </div>
            <Typography variant="h2" className="text-black font-bold text-3xl lg:text-4xl">
              Plus de 5500 clients satisfaits nous font confiance
            </Typography>
            <Typography variant="paragraph" className="text-gray-800 text-lg">
              Découvrez pourquoi Dimo Diagnostic est noté "Excellent" sur Trustpilot. Des milliers
              de propriétaires et professionnels de l'immobilier témoignent de la qualité de nos
              services.
            </Typography>
            <div className="flex gap-4">
              <Button
                size="lg"
                style={{ backgroundColor: colors.dimoPrimary }}
                className="flex items-center gap-2 normal-case"
                onClick={() => window.open('https://fr.trustpilot.com/review/dimo-diagnostic.net', '_blank')}
              >
                Voir tous les avis Trustpilot
              </Button>
            </div>
          </div>

          {/* <div className="lg:w-1/2">
            <div className="grid grid-cols-3 gap-4">
              {videoPlaceholders.map((video) => (
                <div
                  key={video.id}
                  className={`aspect-[3/4] rounded-xl ${video.preview} overflow-hidden relative cursor-pointer transform transition-all duration-300 ${
                    hoveredVideo === video.id ? "scale-105 shadow-lg" : ""
                  }`}
                  onMouseEnter={() => setHoveredVideo(video.id)}
                  onMouseLeave={() => setHoveredVideo(null)}
                >
                  <div className="absolute inset-0 flex flex-col items-center justify-center p-4">
                    <div
                      className={`w-12 h-12 rounded-full bg-white/90 flex items-center justify-center mb-4 transform transition-transform duration-300 ${
                        hoveredVideo === video.id ? "scale-110" : ""
                      }`}
                    >
                      <svg
                        className="w-6 h-6 text-gray-800"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M8 5v10l8-5-8-5z" />
                      </svg>
                    </div>
                    {hoveredVideo === video.id && (
                      <div className="text-center animate-fade-in">
                        <Typography className="font-medium text-gray-800 mb-1">
                          {video.title}
                        </Typography>
                        <Typography className="text-sm text-gray-700">
                          {video.author}
                        </Typography>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div> */}
        </div>
      </Card>

      {/* Section "Ce que disent nos clients" */}
      <Card className="p-6 shadow-md mb-6">
        <Typography variant="h3" className="mb-6 text-black">
          Ce que disent nos clients
        </Typography>
        <div className="space-y-6">
          {/* Widget Trustpilot */}
          <div
            className="trustpilot-widget"
            data-locale="fr-FR"
            data-template-id="53aa8912dec7e10d38f59f36"
            data-businessunit-id="5f4375bc26242f0001c97b8b"
            data-style-height="130px"
            data-style-width="100%"
            data-theme="light"
            data-stars="5"
            data-review-languages="fr"
          >
            <a
              href="https://fr.trustpilot.com/review/dimo-diagnostic.net"
              target="_blank"
              rel="noopener noreferrer"
            >
              Trustpilot
            </a>
          </div>

          {/* Bloc Immodvisor intégré */}
          <div className="bg-white rounded-lg overflow-hidden">
            <div className="md:flex">
              {/* Section d'évaluation globale fixe à 200px */}
              <div className="md:w-[200px] p-6 ">
                <div className="text-center">
                  <h2 className="text-2xl font-semibold text-gray-800 mb-2">4.7/5</h2>
                  <div className="flex justify-center items-center mb-2">
                    {[...Array(5)].map((_, i) => (
                      <svg
                        key={i}
                        className="w-6 h-6 text-[#FF3478]"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.286 3.957a1 1 0 00.95.69h4.162c.969 0 1.371 1.24.588 1.81l-3.37 2.448a1 1 0 00-.364 1.118l1.287 3.957c.3.921-.755 1.688-1.54 1.118l-3.37-2.448a1 1 0 00-1.175 0l-3.37 2.448c-.784.57-1.838-.197-1.539-1.118l1.287-3.957a1 1 0 00-.364-1.118L2.07 9.384c-.783-.57-.38-1.81.588-1.81h4.162a1 1 0 00.95-.69l1.286-3.957z" />
                      </svg>
                    ))}
                  </div>
                  <p className="text-gray-600 mb-4">
                    Plus de <strong>200 avis</strong>
                  </p>
                  <img src="/immodvisor.png" alt="Immodvisor" className="w-32 mx-auto" />
                </div>
              </div>
              {/* Section des avis récents visible uniquement sur md et plus, alignés en ligne */}
              <div className="hidden md:flex flex-row gap-4 p-6 overflow-x-auto flex-1">
                {[
  {
    title: "Professionnalisme",
    text: "Professionnalisme, courtoisie, prise en compte des éléments signalés et fournis en amont. Je recommande.",
    author: "Cambat",
    time: "27 janvier 2025",
    verified: true,
    rating: 5
  },
  {
    title: "Prestation nickel",
    text: "L'équipe Dimo a su faire preuve d'une bonne réactivité et d'un bon suivi.",
    author: "Fabienne LS",
    time: "26 janvier 2025",
    verified: true,
    rating: 5
  },
  {
    title: "Très satisfait",
    text: "Intervention rapide et efficace. Diagnostic complet et bien expliqué.",
    author: "Patrice C",
    time: "25 janvier 2025",
    verified: true,
    rating: 5
  },
  {
    title: "Service impeccable",
    text: "Rendez-vous obtenu rapidement, diagnostiqueur ponctuel et professionnel.",
    author: "CT",
    time: "24 janvier 2025",
    verified: true,
    rating: 5
  }
].map((review, index) => (
                  <a
                    key={index}
                    href="https://www.immodvisor.com/pro/dimo-diagnostic-paris-75008-68883.html"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex-1 min-w-[250px] bg-gray-50 p-4 rounded-lg hover:shadow-lg transition-shadow"
                  >
                    <div className="flex items-center mb-2">
                      <div className="flex">
                        {[...Array(review.rating)].map((_, i) => (
                          <svg
                            key={i}
                            className="w-4 h-4 text-[#FF3478]"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.286 3.957a1 1 0 00.95.69h4.162c.969 0 1.371 1.24.588 1.81l-3.37 2.448a1 1 0 00-.364 1.118l1.287 3.957c.3.921-.755 1.688-1.54 1.118l-3.37-2.448a1 1 0 00-1.175 0l-3.37 2.448c-.784.57-1.838-.197-1.539-1.118l1.287-3.957a1 1 0 00-.364-1.118L2.07 9.384c-.783-.57-.38-1.81.588-1.81h4.162a1 1 0 00.95-.69l1.286-3.957z" />
                          </svg>
                        ))}
                      </div>
                      {review.verified && (
                        <span className="ml-2 text-gray-500 text-xs flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-3 h-3 mr-1"
                          >
                            <path
                              fillRule="evenodd"
                              d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307z"
                              clipRule="evenodd"
                            />
                            <path d="M15.75 9l-4.5 4.5-2.25-2.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                          Vérifié
                        </span>
                      )}
                    </div>
                    <h3 className="text-gray-800 font-medium">{review.title}</h3>
                    <p className="text-gray-600 text-sm my-2">{review.text}</p>
                    <p className="text-gray-500 text-xs">
                      {review.author}
                    </p>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Card>

      {/* Section "Tous nos avis clients" */}
      <Card className="p-6 shadow-md mb-6">
        <Typography variant="h3" className="mb-6 text-black">
          Les derniers avis
        </Typography>
        <div
          className="trustpilot-widget"
          data-locale="fr-FR"
          data-template-id="539adbd6dec7e10e686debee"
          data-businessunit-id="5f4375bc26242f0001c97b8b"
          data-style-height="500px"
          data-style-width="100%"
          data-stars="3,4,5"
          data-review-languages="fr"
        >
          <a href="https://fr.trustpilot.com/review/dimo-diagnostic.net" target="_blank" rel="noopener">
            Trustpilot
          </a>
        </div>
      </Card>
    </Layout>
  );
}
