import React from "react";
import { Card, Typography, Button } from "@material-tailwind/react";
import { 
  DocumentTextIcon, 
  ArrowDownTrayIcon, 
  PaperAirplaneIcon, 
  ShareIcon,
  IdentificationIcon,
  MapPinIcon,
  HomeIcon,
  CalendarIcon,
  ArrowTopRightOnSquareIcon,
  ClockIcon,
  UserIcon,
  ExclamationTriangleIcon,
  ClockIcon as HourglassIcon,
} from "@heroicons/react/24/outline";
import QuickActions from "./QuickActions";
import { colors } from "../utils/colors";
import { useNavigate } from "react-router-dom";

export default function DiagnosticsContent({ propertyInfo, onPrintClick, onPayClick, onSendClick, onShareClick, onHelpClick, onDownloadClick, onCannotProvideDocument, PropertySelectorComponent, isLoadingDocuments, hasReportDocuments }) {
  const navigate = useNavigate();

  const renderDiagnosticReport = () => {
    //ajouter si appointment est pas null
    if (!propertyInfo.appointmentIsPast && !propertyInfo.isDownloadable && propertyInfo.appointment) {
      return (
        <div className="bg-gray-100 p-4 rounded-lg">
          <div className="flex items-center space-x-2 mb-2">
            <CalendarIcon className="h-5 w-5" style={{ color: colors.dimoDark3 }} />
            <Typography variant="small" className="text-black font-medium">
              Rendez-vous confirmé
            </Typography>
          </div>
          <Typography variant="small" className="text-gray-600 mb-4">
          Votre rendez-vous est confirmé. Préparez dès maintenant votre rendez-vous.
          </Typography>
          <Button 
            style={{ backgroundColor: colors.dimoPrimary }} 
            className="flex items-center gap-2"
            onClick={() => window.open(`/appointment/${propertyInfo.id}`, '_blank')}
          >
            <CalendarIcon className="h-4 w-4" /> Préparer mon RDV
          </Button>
        </div>
      );
    }
    //géèrer le cas ou appointment est null (il ne faut juste pas afficher le bouton de préparation du rendez-vous, et afficher un message comme quoi le rendez-vous est programmé)
    else if (!propertyInfo.appointmentIsPast && !propertyInfo.isDownloadable && !propertyInfo.appointment) {
      return (
        <div className="bg-gray-100 p-4 rounded-lg">
          <div className="flex items-center space-x-2 mb-2">
            <CalendarIcon className="h-5 w-5" style={{ color: colors.dimoDark3 }} />
            <Typography variant="small" className="text-black font-medium">
              Rendez-vous programmé
            </Typography>
          </div>
          <Typography variant="small" className="text-gray-600">
          Votre rendez-vous est programmé le {propertyInfo.appointmentText}. En cas d'empêchement, merci de nous contacter par e-mail ou par téléphone.
          </Typography>
        </div>
      );
    }

    /*else if (propertyInfo.actionsNeeded.titleOfProperty) {
      return (
        <div className="bg-gray-100 p-4 rounded-lg">
          <div className="flex items-center space-x-2 mb-2">
            <ExclamationTriangleIcon className="h-5 w-5" style={{ color: colors.dimoDark3 }} />
            <Typography variant="small" className="text-black font-medium">
              Titre de propriété requis
            </Typography>
          </div>
          <Typography variant="small" className="text-gray-600 mb-4">
            Vous devez fournir votre titre de propriété avant de pouvoir accéder à votre rapport de diagnostic.
          </Typography>
          <Button 
            style={{ backgroundColor: colors.dimoPrimary }} 
            className="flex items-center gap-2"
            onClick={() => window.open(`/documents/${propertyInfo.id}`, '')}
          >
            Fournir le titre de propriété
          </Button>
        </div>
      );
    }
    else if (propertyInfo.actionsNeeded.fiscalId) {
      return (
        <div className="bg-gray-100 p-4 rounded-lg">
          <div className="flex items-center space-x-2 mb-2">
            <ExclamationTriangleIcon className="h-5 w-5" style={{ color: colors.dimoDark3 }} />
            <Typography variant="small" className="text-black font-medium">
              Identifiant fiscal requis
            </Typography>
          </div>
          <Typography variant="small" className="text-gray-600 mb-4">
            Vous devez renseigner l'identifiant fiscal de votre local avant de pouvoir accéder à votre rapport de diagnostic.
          </Typography>
          <Button 
            style={{ backgroundColor: colors.dimoPrimary }} 
            className="flex items-center gap-2"
            onClick={() => window.open(`/fiscal-id/${propertyInfo.id}`, '')}
          >
            Renseigner l'identifiant fiscal
          </Button>
        </div>
      );
    }*/
    else if (!propertyInfo.isPayable) {
      return (
        <div className="bg-gray-100 p-4 rounded-lg">
          <div className="flex items-center space-x-2 mb-2">
            <HourglassIcon className="h-5 w-5" style={{ color: colors.dimoDark3 }} />
            <Typography variant="small" className="text-black font-medium">
              Facture en attente
            </Typography>
          </div>
          <Typography variant="small" className="text-gray-600">
          Votre dossier est en attente de traitement par notre équipe de facturation. Vous recevrez un e-mail dès que votre facture sera disponible. Une fois la facture payée, vous pourrez accéder à votre dossier.
          </Typography>
        </div>
      );
    } else if (!propertyInfo.isPaided) {
      return (
        <div className="bg-gray-100 p-4 rounded-lg">
          <div className="flex items-center space-x-2 mb-2">
            <ExclamationTriangleIcon className="h-5 w-5" style={{ color: colors.dimoDark3 }} />
            <Typography variant="small" className="text-black font-medium">
              Paiement requis
            </Typography>
          </div>
          <Typography variant="small" className="text-gray-600">
          Le paiement est nécessaire pour accéder à votre rapport de diagnostics. Si vous avez déjà effectué le paiement, merci de patienter pendant son traitement. Le délai peut varier selon le mode de paiement.
          </Typography>
          {propertyInfo.paymentLink && (
            <Button 
              style={{ backgroundColor: colors.dimoPrimary }} 
              className="mt-4"
              onClick={() => window.open(propertyInfo.paymentLink, '_blank')}
            >
              Procéder au paiement
            </Button>
          )}
        </div>
      );
    } else if (propertyInfo.dpeStatus) {
      return (
        <div className="bg-gray-100 p-4 rounded-lg">
          <div className="flex items-center space-x-2 mb-2">
            <ExclamationTriangleIcon className="h-5 w-5" style={{ color: colors.dimoDark3 }} />
            <Typography variant="small" className="text-black font-medium">
              Accord pour le DPE requis
            </Typography>
          </div>
          <Typography variant="small" className="text-gray-600 mb-4">
          Vous devez remplir le formulaire d'accord DPE avant de pouvoir accéder à votre rapport de diagnostic.
          </Typography>
          <Button 
            style={{ backgroundColor: colors.dimoPrimary }} 
            className="flex items-center gap-2"
            onClick={() => window.open(`/dpe/${propertyInfo.id}`, '')}
          >
            Remplir le formulaire DPE
          </Button>
        </div>
      );
    } else if (!propertyInfo.isDownloadable) {
      return (
        <div className="bg-gray-100 p-4 rounded-lg">
          <div className="flex items-center space-x-2 mb-2">
            <HourglassIcon className="h-5 w-5" style={{ color: colors.dimoDark3 }} />
            <Typography variant="small" className="text-black font-medium">
              Rapport en préparation
            </Typography>
          </div>
          <Typography variant="small" className="text-gray-600">
          Le rapport de diagnostics n'est pas encore prêt pour le téléchargement. Nos équipes s'efforcent de le préparer dans les meilleurs délais.
          </Typography>
        </div>
      );
    } else {
      return (
        <>
          <div className="flex items-center space-x-2 mb-4">
            <DocumentTextIcon className="h-5 w-5" style={{ color: colors.dimoDark3 }} />
            <Typography variant="small" className="text-black">
              Dossier(s) de Diagnostic(s) Technique(s) (DDT)
            </Typography>
          </div>
          {isLoadingDocuments ? (
            <div className="flex flex-col items-center space-y-4 py-8 bg-gray-50 rounded-lg border border-gray-100">
              <div className="relative">
                <div className="w-16 h-16 border-4 border-gray-200 border-dashed rounded-full animate-spin"></div>
                <div className="absolute inset-0 flex items-center justify-center">
                  <DocumentTextIcon className="h-6 w-6" style={{ color: colors.dimoPrimary }} />
                </div>
              </div>
              <div className="text-center">
                <Typography variant="small" className="text-gray-700 font-medium">
                  Chargement de votre dossier...
                </Typography>
                <Typography variant="small" className="text-gray-500 mt-1">
                  Nous récupérons les dernières informations
                </Typography>
              </div>
            </div>
          ) : hasReportDocuments ? (
            <>
              <Typography variant="small" className="mb-4 text-black">
                Votre dossier de diagnostics techniques immobiliers disponible en téléchargement
              </Typography>
              <div className="flex flex-wrap gap-4">
                <Button style={{ backgroundColor: colors.dimoPrimary }} className="flex items-center gap-2" onClick={onDownloadClick}>
                  <ArrowDownTrayIcon className="h-4 w-4" /> Télécharger
                </Button>
                <Button 
                  variant="outlined" 
                  style={{ borderColor: colors.dimoPrimary, color: colors.dimoPrimary }} 
                  className="flex items-center gap-2 hover:bg-gray-50 cursor-pointer hover:shadow-lg hover:opacity-100" 
                  onClick={onSendClick}
                > 
                  <PaperAirplaneIcon className="h-4 w-4" /> Envoyer
                </Button>

                { propertyInfo.actions.share && (
                <Button 
                  variant="outlined" 
                  style={{ borderColor: colors.dimoPrimary, color: colors.dimoPrimary }} 
                  className="flex items-center gap-2 hover:bg-gray-50 cursor-pointer hover:shadow-lg hover:opacity-100" 
                  onClick={onShareClick}
                >
                  <ShareIcon className="h-4 w-4" /> Partager l'accès
                </Button>
                )}
              </div>
            </>
          ) : (
            <div className="bg-gray-100 p-4 rounded-lg">
              <div className="flex items-center space-x-2 mb-2">
                <HourglassIcon className="h-5 w-5" style={{ color: colors.dimoDark3 }} />
                <Typography variant="small" className="text-black font-medium">
                  Rapport non disponible
                </Typography>
              </div>
              <Typography variant="small" className="text-gray-600">
                Le rapport n'est pas encore disponible. Nous venons d'envoyer un rappel au technicien. Vous recevrez un email dès que le rapport sera disponible.
              </Typography>
            </div>
          )}
        </>
      );
    }
  };

  const renderRequiredDocuments = () => {
    const hasRequiredDocuments = propertyInfo.actionsNeeded.titleOfPropertyMissing || 
                                propertyInfo.actionsNeeded.fiscalIdMissing || 
                                propertyInfo.actionsNeeded.maintenanceReportMissing ||
                                propertyInfo.actionsNeeded.energyRenovationMissing ||
                                propertyInfo.actionsNeeded.dpe;

    if (!hasRequiredDocuments) return null;

    const actionsCount = [
      propertyInfo.actionsNeeded.titleOfPropertyMissing,
      propertyInfo.actionsNeeded.fiscalIdMissing,
      propertyInfo.actionsNeeded.maintenanceReportMissing,
      propertyInfo.actionsNeeded.energyRenovationMissing,
      propertyInfo.actionsNeeded.dpe,
      propertyInfo.actionsNeeded.consumptionInvoiceMissing,
    ].filter(Boolean).length;

    return (
      <Card className="p-6 shadow-md mb-6 bg-red-50">
        <Typography variant="h4" className="mb-4 text-black">
          {actionsCount > 1 ? "Actions" : "Action"} à mener sur votre dossier
        </Typography>
        <div className="space-y-4">
          <div>
            <Typography variant="small" className="text-red-800 font-medium">
              {propertyInfo.appointmentIsPast ? 
                "Pour obtenir votre rapport complet avec le DPE, merci de fournir les éléments manquants ci-dessous. Une fois les éléments fournis, le nouveau rapport sera disponible dans les jours qui suivent, vous recevrez un e-mail lorsqu'il sera prêt." :
                "Sans les éléments ci-dessous, votre rapport sera envoyé sans la partie DPE. Pour obtenir un rapport complet, merci de les fournir avant le rendez-vous."
              }
            </Typography>
          </div>
          {propertyInfo.actionsNeeded.dpe && (
            <div className="bg-white border border-gray-300 rounded-lg p-4">
              <div className="flex flex-col md:flex-row md:items-center gap-4">
                <div className="space-y-1 flex-1">
                  <Typography variant="paragraph" className="font-medium text-black">
                    Consentement DPE
                  </Typography>
                  <Typography variant="small" className="text-gray-700">
                    La complétion du formulaire de consentement dans le cadre des surveillances des diagnostiqueurs pour le DPE est requis.
                  </Typography>
                </div>
                <div className="w-full md:w-auto md:min-w-[300px]">
                  <Button 
                    style={{ backgroundColor: colors.dimoPrimary }} 
                    className="normal-case w-full text-center px-2 py-2"
                    onClick={() => navigate(`/dpe/${propertyInfo.id}`)}
                  >
                    Je signe le formulaire de consentement
                  </Button>
                </div>
              </div>
            </div>
          )}

          {propertyInfo.actionsNeeded.fiscalIdMissing && (
            <div className="bg-white border border-gray-300 rounded-lg p-4">
              <div className="flex flex-col md:flex-row md:items-center gap-4">
                <div className="space-y-1 flex-1">
                  <Typography variant="paragraph" className="font-medium text-black">
                    Fournir l'identifiant fiscal
                  </Typography>
                  <Typography variant="small" className="text-gray-700">
                    L'identifiant fiscal de votre local est nécessaire pour la réalisation du DPE.{' '}
                    <a href="https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000043353335" className="text-blue-600 hover:underline"> Annexe 1 de l'arrêté du 31 mars 2021</a>
                  </Typography>
                </div>
                <div className="w-full md:w-auto md:min-w-[300px] flex flex-col gap-2">
                  <Button 
                    style={{ backgroundColor: colors.dimoPrimary }} 
                    className="normal-case w-full text-center px-2 py-2"
                    onClick={() => navigate(`/fiscal-id/${propertyInfo.id}`)}
                  >
                    Je fournis l'identifiant fiscal du bien
                  </Button>
                </div>
              </div>
            </div>
          )}

          {propertyInfo.actionsNeeded.titleOfPropertyMissing && (
            <div className="bg-white border border-gray-300 rounded-lg p-4">
              <div className="flex flex-col md:flex-row md:items-center gap-4">
                <div className="space-y-1 flex-1">
                  <Typography variant="paragraph" className="font-medium text-black">
                    Fournir le titre de propriété
                  </Typography>
                  <Typography variant="small" className="text-gray-700">
                    Le titre de propriété est nécessaire pour la réalisation de votre diagnostic.
                  </Typography>
                </div>
                <div className="w-full md:w-auto md:min-w-[300px] flex flex-col gap-2">
                  <Button 
                    variant="outlined"
                    style={{ borderColor: colors.dimoPrimary, color: colors.dimoPrimary }} 
                    className="normal-case w-full text-center px-2 py-2"
                    onClick={() => onCannotProvideDocument('title')}
                  >
                    Je ne peux pas fournir ce document
                  </Button>
                  <Button 
                    style={{ backgroundColor: colors.dimoPrimary }} 
                    className="normal-case w-full text-center px-2 py-2"
                    onClick={() => navigate(`/documents/${propertyInfo.id}`)}
                  >
                    Je fournis le titre de propriété
                  </Button>
                </div>
              </div>
            </div>
          )}

          {propertyInfo.actionsNeeded.maintenanceReportMissing && (
            <div className="bg-white border border-gray-300 rounded-lg p-4">
              <div className="flex flex-col md:flex-row md:items-center gap-4">
                <div className="space-y-1 flex-1">
                  <Typography variant="paragraph" className="font-medium text-black">
                    Fournir les rapports d'entretien
                  </Typography>
                  <Typography variant="small" className="text-gray-700">
                    Les rapports d'entretien de vos systèmes (chaudière, climatisation, etc.) sont nécessaires pour la réalisation du DPE.
                  </Typography>
                </div>
                <div className="w-full md:w-auto md:min-w-[300px] flex flex-col gap-2">
                  <Button 
                    variant="outlined"
                    style={{ borderColor: colors.dimoPrimary, color: colors.dimoPrimary }} 
                    className="normal-case w-full text-center px-2 py-2"
                    onClick={() => onCannotProvideDocument('maintenance')}
                  >
                    Je ne peux pas fournir ces rapports
                  </Button>
                  <Button 
                    style={{ backgroundColor: colors.dimoPrimary }} 
                    className="normal-case w-full text-center px-2 py-2"
                    onClick={() => navigate(`/documents/${propertyInfo.id}`)}
                  >
                    Je fournis les rapports d'entretien
                  </Button>
                </div>
              </div>
            </div>
          )}

          {propertyInfo.actionsNeeded.energyRenovationMissing && (
            <div className="bg-white border border-gray-300 rounded-lg p-4">
              <div className="flex flex-col md:flex-row md:items-center gap-4">
                <div className="space-y-1 flex-1">
                  <Typography variant="paragraph" className="font-medium text-black">
                    Fournir les factures de rénovations énergétiques (individuelles et de la copropriété)
                  </Typography>
                  <Typography variant="small" className="text-gray-700">
                    Les factures de rénovations énergétiques individuelles et de la copropriété sont nécessaires pour la réalisation du DPE.
                  </Typography>
                </div>
                <div className="w-full md:w-auto md:min-w-[300px] flex flex-col gap-2">
                  <Button 
                    variant="outlined"
                    style={{ borderColor: colors.dimoPrimary, color: colors.dimoPrimary }} 
                    className="normal-case w-full text-center px-2 py-2"
                    onClick={() => onCannotProvideDocument('renovation')}
                  >
                    Je ne peux pas fournir ces factures
                  </Button>
                  <Button 
                    style={{ backgroundColor: colors.dimoPrimary }} 
                    className="normal-case w-full text-center px-2 py-2"
                    onClick={() => navigate(`/documents/${propertyInfo.id}`)}
                  >
                    Je fournis les factures
                  </Button>
                </div>
              </div>
            </div>
          )}

          {propertyInfo.actionsNeeded.consumptionInvoiceMissing && (
            <div className="bg-white border border-gray-300 rounded-lg p-4">
              <div className="flex flex-col md:flex-row md:items-center gap-4">
                <div className="space-y-1 flex-1">
                  <Typography variant="paragraph" className="font-medium text-black">
                    Fournir les factures de consommation d'énergie
                  </Typography>
                  <Typography variant="small" className="text-gray-700">
                    Les factures de consommation d'énergie sont nécessaires pour la réalisation de votre diagnostic.
                  </Typography>
                </div>
                <div className="w-full md:w-auto md:min-w-[300px] flex flex-col gap-2">
                  <Button 
                    variant="outlined"
                    style={{ borderColor: colors.dimoPrimary, color: colors.dimoPrimary }} 
                    className="normal-case w-full text-center px-2 py-2"
                    onClick={() => onCannotProvideDocument('consumption')}
                  >
                    Je ne peux pas fournir ces factures
                  </Button>
                  <Button 
                    style={{ backgroundColor: colors.dimoPrimary }} 
                    className="normal-case w-full text-center px-2 py-2"
                    onClick={() => navigate(`/documents/${propertyInfo.id}`)}
                  >
                    Je fournis les factures
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </Card>
    );
  };

  return (
    <div className="mb-6">
      <Card className="p-6 shadow-md mb-6">
        {PropertySelectorComponent && (
          <div className="mb-6">{PropertySelectorComponent}</div>
        )}

        <Typography variant="h3" className="mb-4 text-black">
          Informations du diagnostic {propertyInfo.id}
        </Typography>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="space-y-4">
            <div className="flex items-center space-x-2">
              <IdentificationIcon className="h-5 w-5" style={{ color: colors.dimoDark3 }} />
              <Typography variant="small" className="text-black">
                Dossier: {propertyInfo.id}
              </Typography>
            </div>
            <div className="flex items-center space-x-2">
              <UserIcon className="h-5 w-5" style={{ color: colors.dimoDark3 }} />
              <Typography variant="small" className="text-black">
                Nom: {propertyInfo.name}
              </Typography>
            </div>
            <div className="flex items-center space-x-2">
              <HomeIcon className="h-5 w-5" style={{ color: colors.dimoDark3 }} />
              <Typography variant="small" className="text-black">
                Type de bien: {propertyInfo.typeProperty}
              </Typography>
            </div>
          </div>
          <div className="space-y-4">
            <div className="flex items-center space-x-2">
              <ClockIcon className="h-5 w-5" style={{ color: colors.dimoDark3 }} />
              <Typography variant="small" className="text-black">
                Date du rendez-vous: {propertyInfo.appointmentText}
              </Typography>
            </div>
            <div className="flex items-center space-x-2">
              <ArrowTopRightOnSquareIcon className="h-5 w-5" style={{ color: colors.dimoDark3 }} />
              <Typography variant="small" className="text-black">
                Superficie: {propertyInfo.square} m²
              </Typography>
            </div>
            <div className="flex items-center space-x-2">
              <CalendarIcon className="h-5 w-5" style={{ color: colors.dimoDark3 }} />
              <Typography variant="small" className="text-black">
                Année de construction: {propertyInfo.buildingYear}
              </Typography>
            </div>
          </div>
          <div className="space-y-4">
            <div className="flex items-center space-x-2">
              <MapPinIcon className="h-5 w-5" style={{ color: colors.dimoDark3 }} />
              <Typography variant="small" className="text-black">
                Adresse: {propertyInfo.address}
              </Typography>
            </div>
            {propertyInfo.diagnostics && propertyInfo.diagnostics.length > 0 && (
              <div className="flex items-start space-x-2">
                <DocumentTextIcon className="h-5 w-5 mt-0.5 flex-shrink-0" style={{ color: colors.dimoDark3 }} />
                <Typography variant="small" className="text-black">
                  Diagnostics: {propertyInfo.diagnostics.join(' • ')}
                </Typography>
              </div>
            )}
          </div>
        </div>
      </Card>

      {propertyInfo.isPaided && renderRequiredDocuments()}

      <Card className="p-6 shadow-md mb-6">
          <Typography variant="h4" className="mb-4 text-black">
            {propertyInfo.isDownloadable ? "Rapport de diagnostics" : "État du dossier"}
          </Typography>
          {renderDiagnosticReport()}
      </Card>
      
      <QuickActions 
        onPrintClick={onPrintClick} 
        onPayClick={onPayClick} 
        onSendClick={onSendClick} 
        onShareClick={onShareClick} 
        onHelpClick={onHelpClick} 
        propertyInfo={propertyInfo} 
        onDownloadClick={onDownloadClick} 
      />
    </div>
  );
}