import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card } from "@material-tailwind/react";
import Layout from "../components/layout/Layout";
import PropertySelector from "../components/PropertySelector";
import DiagnosticsContent from "../components/DiagnosticsContent";
import PrintModal from "../components/modals/PrintModal";
import PaymentModal from "../components/modals/PaymentModal";
import SendModal from "../components/modals/SendModal";
import ShareAccessModal from "../components/modals/ShareAccessModal";
import DownloadModal from "../components/modals/DownloadModal";
import axiosInstance from '../utils/axiosInstance';
import { Helmet } from 'react-helmet';
import { useProperties } from "../contexts/PropertiesContext";

function LoadingSkeleton() {
  return (
    <div className="mb-6">
      {/* Première carte - Informations du diagnostic */}
      <Card className="p-6 shadow-md mb-6">
        <div className="space-y-6 animate-pulse">
          {/* Titre */}
          <div className="h-8 bg-gray-200 rounded w-1/3"></div>

          {/* Grille d'informations */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {/* Première colonne - Dossier/Nom/Type */}
            <div className="space-y-4">
              {[...Array(3)].map((_, i) => (
                <div key={`col1-${i}`} className="flex items-center gap-2">
                  <div className="w-5 h-5 rounded-full bg-gray-200"></div>
                  <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                </div>
              ))}
            </div>

            {/* Deuxième colonne - Date/Superficie/Année */}
            <div className="space-y-4">
              {[...Array(3)].map((_, i) => (
                <div key={`col2-${i}`} className="flex items-center gap-2">
                  <div className="w-5 h-5 rounded-full bg-gray-200"></div>
                  <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                </div>
              ))}
            </div>

            {/* Troisième colonne - Adresse/Diagnostics */}
            <div className="space-y-4">
              <div className="flex items-center gap-2">
                <div className="w-5 h-5 rounded-full bg-gray-200"></div>
                <div className="h-4 bg-gray-200 rounded w-3/4"></div>
              </div>
              <div className="flex items-start gap-2">
                <div className="w-5 h-5 rounded-full bg-gray-200"></div>
                <div className="flex-1">
                  <div className="h-4 bg-gray-200 rounded w-full mb-2"></div>
                  <div className="flex flex-wrap gap-2">
                    {[...Array(3)].map((_, i) => (
                      <div key={`diag-${i}`} className="h-4 bg-gray-200 rounded w-20"></div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>

      {/* Deuxième carte - État du dossier/Rapport */}
      <Card className="p-6 shadow-md mb-6">
        <div className="space-y-6 animate-pulse">
          {/* Titre */}
          <div className="h-6 bg-gray-200 rounded w-1/4 mb-4"></div>

          {/* Contenu */}
          <div className="space-y-4">
            <div className="flex items-center gap-2">
              <div className="w-5 h-5 rounded-full bg-gray-200"></div>
              <div className="h-4 bg-gray-200 rounded w-1/3"></div>
            </div>
            <div className="h-4 bg-gray-200 rounded w-1/2"></div>
            <div className="flex gap-4">
              <div className="h-10 bg-gray-200 rounded w-32"></div>
              <div className="h-10 bg-gray-200 rounded w-32"></div>
              <div className="h-10 bg-gray-200 rounded w-32"></div>
            </div>
          </div>
        </div>
      </Card>

      {/* Troisième carte - Actions rapides */}
      <Card className="p-6 shadow-md mb-6">
        <div className="space-y-6 animate-pulse">
          {/* Titre */}
          <div className="h-6 bg-gray-200 rounded w-2/5 mb-4"></div>

          {/* Grille d'actions */}
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4">
            {[...Array(5)].map((_, i) => (
              <div key={`action-${i}`} className="p-4 flex flex-col items-center space-y-3 bg-gray-50 border border-gray-200 rounded-xl">
                <div className="w-10 h-10 rounded-full bg-gray-200"></div>
                <div className="h-5 bg-gray-200 rounded w-20"></div>
                <div className="h-4 bg-gray-200 rounded w-full"></div>
              </div>
            ))}
          </div>
        </div>
      </Card>
    </div>
  );
}

export default function Diagnostics() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { properties, loading: propertiesLoading, refreshProperties } = useProperties();
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isSendModalOpen, setIsSendModalOpen] = useState(false);
  const [isShareAccessModalOpen, setIsShareAccessModalOpen] = useState(false);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [documents, setDocuments] = useState(null);
  const [documentsWithoutInvoice, setDocumentsWithoutInvoice] = useState(null);
  const [isLoadingDocuments, setIsLoadingDocuments] = useState(false);
  const [isChangingProperty, setIsChangingProperty] = useState(false);

  useEffect(() => {
    if (!propertiesLoading && !isChangingProperty) {
      if (id) {
        const propertyFromSummary = properties.find(prop => prop.id.toString() === id);
        if (propertyFromSummary) {
          setSelectedProperty(propertyFromSummary);
        } else {
          navigate("/diagnostics", { replace: true });
        }
      } else if (properties.length > 0) {
        navigate(`/diagnostics/${properties[0].id}`, { replace: true });
      }
    }
  }, [id, properties, propertiesLoading, isChangingProperty, navigate]);

  useEffect(() => {
    let isCancelled = false;

    if (selectedProperty && !isChangingProperty) {
      setIsLoadingDocuments(true);
      
      axiosInstance.get(`/documents/${selectedProperty.id}`)
        .then((response) => {
          if (!isCancelled) {
            setDocuments(response.data.documents || []);
            const documentsWithoutInvoice = response.data.documents.filter(doc => doc.type !== 'invoice');
            setDocumentsWithoutInvoice(documentsWithoutInvoice);
          }
        })
        .catch((error) => {
          if (!isCancelled) {
            if (error.response && error.response.status === 404) {
              setDocuments([]);
              setDocumentsWithoutInvoice([]);
            } else {
              console.error('Erreur lors de la récupération des documents:', error);
            }
          }
        })
        .finally(() => {
          if (!isCancelled) {
            setIsLoadingDocuments(false);
          }
        });
    }

    return () => {
      isCancelled = true;
    };
  }, [selectedProperty, isChangingProperty]);

  const handlePrint = (printData) => {
    console.log("Print data:", printData);
  };

  const handleSend = (sendData) => {
    console.log("Send data:", sendData);
  };

  const handleShareAccess = (shareData) => {
    console.log("Share access data:", shareData);
  };

  const handlePropertyChange = (newPropertyId) => {
    if (!propertiesLoading && selectedProperty && newPropertyId !== selectedProperty.id) {
      setIsChangingProperty(true);
      // Réinitialiser les documents avant de changer de propriété
      setDocuments(null);
      setDocumentsWithoutInvoice(null);
      setIsLoadingDocuments(true);
      
      navigate(`/diagnostics/${newPropertyId}`, { replace: true });
      
      // Attendre que la navigation soit terminée avant de désactiver le loading
      setTimeout(() => {
        setIsChangingProperty(false);
      }, 400);
    }
  };

  const handleCannotProvideDocument = async (type) => {
    try {
      await axiosInstance.post(`/properties/${selectedProperty.id}/cannot-provide`, {
        type: type
      });
      refreshProperties();
    } catch (error) {
      console.error('Erreur lors de la notification de document non fourni:', error);
    }
  };

  return (
    <Layout>
      <Helmet>
        <title>Diagnostics - Dimo Diagnostic</title>
        <meta name="description" content="Gérez vos dossier de diagnostics immobiliers" />
        <link rel="canonical" href={`https://client.dimo-diagnostic.net/diagnostics${id ? `/${id}` : ''}`} />
      </Helmet>

      {(propertiesLoading || isChangingProperty) ? (
        <LoadingSkeleton />
      ) : (
        <>
          {selectedProperty && (
            <DiagnosticsContent
              key={selectedProperty.id}
              propertyInfo={selectedProperty}
              onPrintClick={() => setIsPrintModalOpen(true)}
              onPayClick={() => setIsPaymentModalOpen(true)}
              onSendClick={() => setIsSendModalOpen(true)}
              onShareClick={() => setIsShareAccessModalOpen(true)}
              onDownloadClick={() => setIsDownloadModalOpen(true)}
              onHelpClick={() => {navigate("/support/" + selectedProperty.id)}}
              onCannotProvideDocument={handleCannotProvideDocument}
              isLoadingDocuments={isLoadingDocuments}
              hasReportDocuments={documents ? documents.some(doc => doc.type === 'report') : false}
              PropertySelectorComponent={
                properties.length > 1 ? (
                  <PropertySelector
                    selectedProperty={selectedProperty ? selectedProperty.id : null}
                    setSelectedProperty={handlePropertyChange}
                    properties={properties}
                    isLoading={false}
                  />
                ) : null
              }
            />
          )}
          <PrintModal
            isOpen={isPrintModalOpen}
            onClose={() => setIsPrintModalOpen(false)}
            onPrint={handlePrint}
            documents={documentsWithoutInvoice}
            isLoadingDocuments={isLoadingDocuments}
            dealId={selectedProperty ? selectedProperty.id : null}
            printAsked={selectedProperty ? selectedProperty.print : false}
          />
          <PaymentModal
            isOpen={isPaymentModalOpen}
            onClose={() => setIsPaymentModalOpen(false)}
            paymentLink={selectedProperty ? selectedProperty.paymentLink : null}
          />
          <SendModal
            isOpen={isSendModalOpen}
            onClose={() => setIsSendModalOpen(false)}
            onSend={handleSend}
            documents={documents}
            isLoadingDocuments={isLoadingDocuments}
            dealId={selectedProperty ? selectedProperty.id : null}
          />
          <ShareAccessModal
            isOpen={isShareAccessModalOpen}
            onClose={() => setIsShareAccessModalOpen(false)}
            dealId={selectedProperty ? selectedProperty.id : null}
            onShareAccess={handleShareAccess}
          />
          <DownloadModal
            isOpen={isDownloadModalOpen}
            onClose={() => setIsDownloadModalOpen(false)}
            documents={documents}
            isLoadingDocuments={isLoadingDocuments}
            dealId={selectedProperty ? selectedProperty.id : null}
            canReview={false}
          />

          {properties.length === 0 && !propertiesLoading && (
            <Card className="mb-6 shadow-md">
              <div className="p-6 text-center">
                <p className="text-red-500">Aucun dossier n'est actuellement disponible pour votre compte.</p>
              </div>
            </Card>
          )}
        </>
      )}
    </Layout>
  );
}

