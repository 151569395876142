import React from "react";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Button,
  Typography,
  IconButton,
} from "@material-tailwind/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { colors } from "../../utils/colors";

export default function ReviewInviteModal({ isOpen, onClose, invitationLink }) {
  return (
    <Dialog open={isOpen} handler={onClose} size="md">
      <DialogHeader className="flex items-center justify-between">
        <Typography variant="h5">Remboursement de votre diagnostic 🎁</Typography>
        <IconButton variant="text" color="blue-gray" onClick={onClose}>
          <XMarkIcon className="h-5 w-5" />
        </IconButton>
      </DialogHeader>
      <DialogBody className="text-center">
        <Typography className="mb-4">
          Saviez-vous que vous pouvez obtenir le remboursement de votre diagnostic en participant à notre tirage au sort ?
        </Typography>
        <Typography className="mb-6">
          Pour participer, c'est très simple. Il vous suffit de partager votre avis sur votre expérience avec DIMO Diagnostic.
        </Typography>
        <Typography variant="small" color="gray" className="mb-4">
          Voir les règles du jeu : <a href="https://www.dimo-diagnostic.net/jeu-concours" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">www.dimo-diagnostic.net/jeu-concours</a>
        </Typography>
      </DialogBody>
      <DialogFooter>
        <Button
          variant="text"
          color="gray"
          onClick={onClose}
          className="mr-2"
        >
          Plus tard
        </Button>
        <Button
          style={{ backgroundColor: colors.dimoPrimary }}
          onClick={() => {
            window.open(invitationLink, '_blank');
            onClose();
          }}
        >
          Je participe
        </Button>
      </DialogFooter>
    </Dialog>
  );
} 