import React, { useState, useEffect } from "react"
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Button,
  Typography,
  IconButton,
  Spinner,
  Card,
} from "@material-tailwind/react"
import {
  ArrowDownTrayIcon,
  DocumentTextIcon,
  XMarkIcon,
  DocumentDuplicateIcon,
  EyeIcon,
  CreditCardIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/outline"
import axios from "axios"
import ReviewInviteModal from "./ReviewInviteModal"

import { colors } from "../../utils/colors";


export default function DownloadModal({ isOpen, onClose, documents = [], isLoadingDocuments, dealId, canReview = false }) {
  const [downloadingDoc, setDownloadingDoc] = useState(null)
  const [isArchiveOpen, setIsArchiveOpen] = useState(false)
  const [showReviewInvite, setShowReviewInvite] = useState(false)
  const [inviteCount, setInviteCount] = useState(0)
  const [invitationLink, setInvitationLink] = useState(null)

  useEffect(() => {
    if (canReview && dealId) {
      // Récupérer le lien d'invitation Trustpilot
      const fetchInvitationLink = async () => {
        try {
          const response = await axios.get(`/api/trustpilot/invitation/${dealId}`);
          if (response.data.success) {
            setInvitationLink(response.data.invitationLink);
          }
        } catch (error) {
          console.error('Erreur lors de la récupération du lien d\'invitation:', error);
        }
      };
      fetchInvitationLink();
    }
  }, [dealId, canReview]);

  const handleDownload = (doc) => {
    setDownloadingDoc(doc.id)
    window.open(doc.downloadLink || doc.file, "_blank")
    setTimeout(() => setDownloadingDoc(null), 1000)
  }

  const handleConsult = (doc) => {
    window.open(doc.webViewLink || doc.file, "_blank")
  }

  const handleDocumentAction = (doc, action) => {
    if (action === 'download') {
      handleDownload(doc);
    } else {
      handleConsult(doc);
    }

    if (canReview && inviteCount < 2 && invitationLink) {
      setTimeout(() => {
        setShowReviewInvite(true);
        setInviteCount(prev => prev + 1);
      }, 3000);
    }
  };

  const renderDocumentIcon = (type) => {
    return type === "report" ? (
      <DocumentDuplicateIcon className="h-10 w-10" style={{ color: colors.dimoPrimary }} />
    ) : (
      <DocumentTextIcon className="h-10 w-10" style={{ color: colors.dimoPrimary }} />
    )
  }

  const renderActionButtons = (doc) => {
    const isInvoice = doc.type === "invoice"
    const isUnpaidInvoice = isInvoice && doc.status !== "paid"

    return (
      <div className="flex flex-col md:flex-row items-center gap-2 w-full md:w-auto mt-2 md:mt-0">
        {isInvoice && (
          <div className="flex items-center gap-2 mr-2">
            <CheckCircleIcon className={`h-4 w-4 ${doc.status === "paid" ? "text-green-500" : "text-orange-500"}`} />
            <Typography variant="small" className={doc.status === "paid" ? "text-green-500" : "text-orange-500"}>
              {doc.status === "paid" ? "Payée" : "En attente"}
            </Typography>
          </div>
        )}
        {isUnpaidInvoice && doc.payLink && (
          <a href={doc.payLink} target="_blank" rel="noopener noreferrer" className="w-full md:w-auto">
            <Button
              size="sm"
              className="w-full flex items-center justify-center gap-2"
              style={{ backgroundColor: colors.dimoPrimary }}
            >
              <CreditCardIcon className="h-4 w-4" />
              Payer
            </Button>
          </a>
        )}
        <Button
          variant="outlined" 
          style={{ borderColor: colors.dimoPrimary, color: colors.dimoPrimary }} 
          size="sm"
          className="w-full md:w-auto flex items-center justify-center gap-2 hover:bg-gray-50 cursor-pointer hover:shadow-md hover:opacity-100"
          onClick={(e) => {
            e.preventDefault();
            handleDocumentAction(doc, 'consult');
          }}
        >
          <EyeIcon className="h-4 w-4" />
          Consulter
        </Button>
        <Button
          variant={isUnpaidInvoice ? "outlined" : "filled"}
          size="sm"
          className="w-full md:w-auto flex items-center justify-center gap-2"
          style={
            isUnpaidInvoice
              ? { borderColor: colors.dimoPrimary, color: colors.dimoPrimary }
              : { backgroundColor: colors.dimoPrimary }
          }
          onClick={(e) => {
            e.preventDefault();
            handleDocumentAction(doc, 'download');
          }}
          disabled={downloadingDoc === doc.id}
        >
          {downloadingDoc === doc.id ? (
            <Spinner className="h-8 w-8" style={{ color: colors.dimoPrimary }} />
          ) : (
            <ArrowDownTrayIcon className="h-4 w-4" />
          )}
          {downloadingDoc === doc.id ? "..." : "Télécharger"}
        </Button>
      </div>
    )
  }

  const renderDocumentCard = (doc, index) => (
    <Card key={index} className="p-4 border border-gray-200 hover:border-gray-300 transition-all duration-300">
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
        <div className="flex items-start gap-4 flex-1">
          {renderDocumentIcon(doc.type)}
          <div className="flex-1 min-w-0">
            <Typography variant="h6" className="mb-1 text-gray-900 break-words">
              {doc.name}
            </Typography>
            {doc.amount && (
              <Typography variant="small" color="gray" className="mb-2">
                Montant: {doc.amount}€
              </Typography>
            )}
          </div>
        </div>
        {renderActionButtons(doc)}
      </div>
    </Card>
  )

  const activeDocuments = documents ? documents.filter((doc) => !doc.name.startsWith("[ARCHIVE]")) : []
  const archivedDocuments = documents ? documents.filter((doc) => doc.name.startsWith("[ARCHIVE]")) : []

  return (
    <>
      <Dialog open={isOpen} handler={onClose} size="lg">
        <DialogHeader className="flex items-center justify-between">
          <Typography variant="h5" className="flex items-center">
            <DocumentTextIcon className="h-6 w-6 mr-2" />
            Liste des documents
          </Typography>
          <IconButton variant="text" color="blue-gray" onClick={onClose}>
            <XMarkIcon className="h-5 w-5" />
          </IconButton>
        </DialogHeader>
        <DialogBody divider className="overflow-y-auto max-h-[60vh]">
          {isLoadingDocuments || !documents ? (
            <div className="flex justify-center items-center py-10">
              <Spinner className="h-8 w-8" style={{ color: colors.dimoPrimary }} />
              <Typography variant="small" color="gray" className="ml-2">
                Chargement des documents, cela peut prendre jusqu'à 30 secondes...
              </Typography>
            </div>
          ) : documents.length > 0 ? (
            <div className="space-y-4">
              {activeDocuments.map((doc, index) => renderDocumentCard(doc, index))}

              {archivedDocuments.length > 0 && (
                <Card className="p-4 border border-gray-200 hover:border-gray-300 transition-all duration-300">
                  <button
                    className="w-full flex items-center justify-between"
                    onClick={() => setIsArchiveOpen(!isArchiveOpen)}
                    aria-expanded={isArchiveOpen}
                    aria-controls="archived-documents"
                  >
                    <Typography variant="h6" className="text-gray-900">
                      Documents archivés ({archivedDocuments.length})
                    </Typography>
                    {isArchiveOpen ? (
                      <ChevronUpIcon className="h-5 w-5 text-gray-500" />
                    ) : (
                      <ChevronDownIcon className="h-5 w-5 text-gray-500" />
                    )}
                  </button>
                  {isArchiveOpen && (
                    <div id="archived-documents" className="mt-4 space-y-4">
                      <Typography variant="small" color="gray">
                      Les documents archivés correspondent à d'anciennes versions désormais remplacées par des versions plus récentes, disponibles ci-dessus. Ces documents ne sont plus valides et ne doivent pas être utilisés. Ils sont conservés ici uniquement à titre de référence. 
                      </Typography>
                      {archivedDocuments.map((doc, index) => renderDocumentCard(doc, index))}
                    </div>
                  )}
                </Card>
              )}
            </div>
          ) : (
            <Card className="p-8 bg-gray-50">
              <div className="text-center">
                <DocumentTextIcon className="h-12 w-12 mx-auto mb-4 text-gray-400" />
                <Typography variant="h6" color="gray" className="mb-2">
                  Aucun document disponible
                </Typography>
                <Typography color="gray">Les documents apparaîtront ici une fois générés.</Typography>
              </div>
            </Card>
          )}
        </DialogBody>
        <DialogFooter>
          <Button 
            variant="outlined" 
            style={{ borderColor: colors.dimoPrimary, color: colors.dimoPrimary }} 
            className="flex items-center gap-2 hover:bg-gray-50 cursor-pointer hover:shadow-lg hover:opacity-100" 
          
          
          onClick={onClose}>
            Fermer
          </Button>
        </DialogFooter>
      </Dialog>
      <ReviewInviteModal 
        isOpen={showReviewInvite} 
        onClose={() => setShowReviewInvite(false)}
        invitationLink={invitationLink}
      />
    </>
  )
}