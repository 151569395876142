import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Typography,
  Button,
  Spinner,
  Card
} from "@material-tailwind/react";
import { XMarkIcon, CheckCircleIcon, GiftIcon, CalendarDaysIcon } from "@heroicons/react/24/outline";
import axiosInstance from "../utils/axiosInstance";
import { colors } from '../utils/colors';
import * as Sentry from "@sentry/react";

const AcceptQuote = ({ onSuccess, isComplete }) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const acceptQuote = async () => {
      if (loading || isComplete) return;
      
      setLoading(true);
      try {
        await axiosInstance.post(`/quotes/${id}/accept`);
        setLoading(false);
        if (onSuccess) {
          onSuccess();
        }
      } catch (err) {
        const errorMessage = "Une erreur est survenue lors de l'acceptation du devis, merci de contacter le service client de Dimo Diagnostic.";
        setError(errorMessage);
        Sentry.captureException(err, {
          extra: {
            quoteId: id,
            action: 'accept_quote'
          }
        });
        console.error("Error accepting quote:", err);
        setLoading(false);
      }
    };

    acceptQuote();
  }, [id, loading, isComplete, onSuccess]);

  if (loading) {
    return (
      <div className="max-w-7xl mx-auto px-4 py-6 flex flex-col items-center justify-center">
        <Spinner className="h-12 w-12 mb-4" style={{ color: colors.dimoPrimary }} />
        <Typography className="text-gray-600">
          Traitement de votre acceptation...
        </Typography>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-7xl mx-auto px-4 py-6 flex flex-col items-center">
        <div className="w-16 h-16 rounded-full bg-red-50 flex items-center justify-center mb-4">
          <XMarkIcon className="h-8 w-8 text-red-500" />
        </div>
        <Typography color="red" className="text-center mb-4">
          {error}
        </Typography>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-6">
      <div className="flex flex-col items-center justify-center mb-8">
        <div className="w-16 h-16 rounded-full bg-green-50 flex items-center justify-center mb-4">
          <CheckCircleIcon className="h-8 w-8 text-green-500" />
        </div>
        <Typography variant="h4" className="text-center mb-2 text-gray-800">
          Merci de votre confiance !
        </Typography>
        <Typography className="text-center text-gray-600 max-w-lg">
          Nous sommes ravis que notre proposition ait retenu votre attention.
        </Typography>
      </div>

      <Card className="p-6 mb-6 bg-gray-50">
        <div className="flex flex-col md:flex-row items-start gap-4">
          <div className="rounded-full bg-white p-3 shrink-0">
            <CalendarDaysIcon className="h-6 w-6" style={{ color: colors.dimoPrimary }} />
          </div>
          <div className="flex-1">
            <Typography variant="h6" className="text-gray-800 mb-2">
              Prochaine étape : Planification du rendez-vous
            </Typography>
            <Typography className="text-gray-600">
              Un conseiller clientèle va prendre contact avec vous dans les plus brefs délais pour organiser votre rendez-vous et vous accompagner dans la suite du processus.
            </Typography>
          </div>
        </div>
      </Card>

      <Card className="p-6 bg-gray-50">
        <div className="flex flex-col md:flex-row items-start gap-4">
          <div className="rounded-full bg-white p-3 shrink-0">
            <GiftIcon className="h-6 w-6" style={{ color: colors.dimoPrimary }} />
          </div>
          <div className="flex-1">
            <Typography variant="h6" className="text-gray-800 mb-2">
              Découvrez nos offres partenaires
            </Typography>
            <Typography className="text-gray-600 mb-4">
              En attendant votre rendez-vous, profitez d'avantages exclusifs auprès de nos partenaires de confiance.
            </Typography>
            <Button
              variant="filled"
              className="flex items-center gap-2 rounded-xl"
              style={{ backgroundColor: colors.dimoPrimary }}
              onClick={() => window.location.href = '/deals'}
            >
              <GiftIcon className="h-4 w-4" />
              Voir les bons plans
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default AcceptQuote; 