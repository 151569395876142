import React, { useEffect } from "react";
import { Select, Option, Spinner, Typography } from "@material-tailwind/react";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import { colors } from "../utils/colors";

export default function QuoteSelector({ selectedQuote, setSelectedQuote, quotes = [], isLoading }) {
  useEffect(() => {
    if (!isLoading && Array.isArray(quotes) && quotes.length > 0 && !selectedQuote) {
      setSelectedQuote(String(quotes[0].id));
    }
  }, [isLoading, quotes, selectedQuote, setSelectedQuote]);

  if (isLoading) {
    return (
      <div className="p-4 flex items-center justify-center">
        <Spinner className="h-8 w-8" style={{ color: colors.dimoPrimary }} />
      </div>
    );
  }

  if (!Array.isArray(quotes)) {
    return (
      <div className="p-4 text-red-500">
        <p>Erreur : Les devis ne sont pas disponibles.</p>
      </div>
    );
  }

  const getQuoteStatus = (status) => {
    switch(status) {
      case 'waiting_response':
        return 'En attente de validation';
      case 'accepted':
        return 'Devis validé';
      case 'rejected':
        return 'Devis refusé';
      default:
        return 'Devis généré';
    }
  };

  return (
    <div className="">
      <Typography variant="h6" color="black" className="mb-2">
        Sélectionnez un devis
      </Typography>
      <Select
        size="lg"
        className="!min-h-[52px] sm:!min-h-[42px]"
        containerProps={{
          className: "min-w-[200px]"
        }}
        labelProps={{
          className: 'before:mr-0 after:ml-0',
        }}
        menuProps={{
          className: "p-2"
        }}
        value={selectedQuote ? String(selectedQuote) : ""}
        onChange={(value) => setSelectedQuote(value)}
      >
        {quotes.map((quote, index) => (
          <Option 
            key={`${quote.id}-${index}`} 
            value={String(quote.id)}
            className="p-3 hover:bg-gray-50 focus:bg-gray-50 active:bg-gray-50 rounded-lg"
          >
            <div className="flex items-center gap-3">
              <DocumentTextIcon className="h-6 w-6 flex-shrink-0" style={{ color: colors.dimoPrimary }} />
              <div className="flex flex-col min-w-0">
                <span className="text-base truncate">
                  Devis n°{quote.id} <span className="hidden md:inline">- {quote.address || 'Adresse non définie'}</span>
                </span>
                <span className="text-sm text-gray-500">
                  {getQuoteStatus(quote.quoteStatus)}
                </span>
              </div>
            </div>
          </Option>
        ))}
      </Select>
    </div>
  );
} 