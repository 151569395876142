import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { List, ListItem, ListItemPrefix, Button } from "@material-tailwind/react";
import {
  DocumentTextIcon,
  QuestionMarkCircleIcon,
  UserIcon,
  HomeIcon,
  CreditCardIcon,
  ArrowUpTrayIcon,
  DocumentCheckIcon,
  CalendarDaysIcon,
  RocketLaunchIcon,
  PlusIcon,
  ExclamationTriangleIcon,
  IdentificationIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ClipboardDocumentListIcon,
  GiftIcon,
  LightBulbIcon,
  ChatBubbleLeftRightIcon
} from "@heroicons/react/24/outline";
import axiosInstance from "../../utils/axiosInstance";
import { colors } from "../../utils/colors";
import Logo from "../../utils/logo";
import { useProperties } from "../../contexts/PropertiesContext";
import { Link } from "react-router-dom";	

export default function Sidebar({ isOpen, closeSidebar }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(location.pathname);
  const { properties, quotes, loading } = useProperties();
  const [isActionsOpen, setIsActionsOpen] = useState(true);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);

  const [notifications, setNotifications] = useState({
    fiscalId: false,
    dpe: false,
    appointment: false,
    documents: false,
    deals: true,
    maintenanceReport: false,
    energyRenovation: false,
    quotes: false,
    titleOfProperty: false,
    consumptionInvoice: false
  });

  const [showQuote, setShowQuote] = useState(false);
  const [showWonItems, setShowWonItems] = useState(false);
  const [showDocuments, setShowDocuments] = useState(false);

  useEffect(() => {
    if (!loading && properties) {
      const newNotifications = {
        fiscalId: properties.some(p => p.actionsNeeded.fiscalId),
        dpe: properties.some(p => p.actionsNeeded.dpe),
        appointment: properties.some(p => p.actionsNeeded.appointment),
        documents: properties.some(p => p.actionsNeeded.customerDocument && !p.appointmentIsPast),
        deals: true,
        maintenanceReport: properties.some(p => p.actionsNeeded.maintenanceReportMissing),
        energyRenovation: properties.some(p => p.actionsNeeded.energyRenovationMissing),
        quotes: quotes && quotes.some(q => q.quoteStatus === 'pending'),
        titleOfProperty: properties.some(p => p.actionsNeeded.titleOfPropertyMissing),
        consumptionInvoice: properties.some(p => p.actionsNeeded.consumptionInvoiceMissing)
      };
      setNotifications(newNotifications);

      setShowQuote(properties.some(p => p.status === 'open') || (quotes && quotes.length > 0));
      setShowWonItems(properties.some(p => p.status === 'won'));
      setShowDocuments(properties.some(p => p.actionsNeeded.customerDocument));
      
      setInitialLoadComplete(true);
    }
  }, [properties, quotes, loading]);

  const handleNavigation = (path) => {
    setActiveTab(path.split('/')[1]);
    navigate(path);
    closeSidebar();
  };

  const handleLogout = async () => {
    try {
      await axiosInstance.get('/logout');
      navigate('/');
    } catch (error) {
      console.error("Erreur lors de la déconnexion:", error);
    }
  };

  const handleNewQuote = () => {
    const token = document.cookie.split('; ').find(row => row.startsWith('authToken='));
    if (token) {
      const tokenValue = token.split('=')[1];
      try {
        const decodedToken = JSON.parse(atob(tokenValue.split('.')[1]));
        if (decodedToken.is_organization) {
          window.open("https://app.dimo-diagnostic.net/mon-offre?s=1&o=devis-pro", "_blank");
        } else {
          window.open("https://www.dimo-diagnostic.net/devis-diagnostic-immobilier-tarifs", "_blank");
        }
      } catch (error) {
        // En cas d'erreur de décodage, on utilise le lien par défaut
        window.open("https://www.dimo-diagnostic.net/devis-diagnostic-immobilier-tarifs", "_blank");
      }
    } else {
      // Si pas de token, on utilise le lien par défaut
      window.open("https://www.dimo-diagnostic.net/devis-diagnostic-immobilier-tarifs", "_blank");
    }
  };

  // Mémoriser les items de navigation qui dépendent des propriétés et des notifications
  const memoizedNavItems = useMemo(() => {
    const mainNavItems = [
      { path: "/dashboard", icon: HomeIcon, label: "Accueil", alwaysShow: true },
      { path: "/quote", icon: RocketLaunchIcon, label: "Devis", showIf: showQuote, notificationKey: "quotes" },
      { path: "/appointment", icon: CalendarDaysIcon, label: "Préparer mon RDV", notificationKey: "appointment", showIf: notifications.appointment },
      { path: "/documents", icon: ArrowUpTrayIcon, label: "Documents", notificationKey: "documents", showIf: showDocuments },
      { path: "/diagnostics", icon: DocumentTextIcon, label: "Diagnostics", showIf: showWonItems },
      { path: "/invoices", icon: CreditCardIcon, label: "Factures", showIf: showWonItems },
      { path: "/deals", icon: GiftIcon, label: "Bons plans", notificationKey: "deals", alwaysShow: true },
      { path: "/reviews", icon: ChatBubbleLeftRightIcon, label: "Expériences et avis", alwaysShow: true },
      { path: "/support", icon: QuestionMarkCircleIcon, label: "Contact & Aide", showIf: properties && properties.length > 0 },
    ];

    return mainNavItems;
  }, [showQuote, notifications.appointment, showDocuments, showWonItems, properties]);

  // Mémoriser les actions requises qui dépendent des notifications
  const memoizedRequiredActions = useMemo(() => {
    return [
      { path: "/fiscal-id", icon: IdentificationIcon, label: "Identifiant fiscal", notificationKey: "fiscalId", showIf: notifications.fiscalId },
      { path: "/dpe", icon: DocumentCheckIcon, label: "Accord DPE", notificationKey: "dpe", showIf: notifications.dpe },
      { path: "/documents?type=maintenance", icon: ArrowUpTrayIcon, label: "Rapports d'entretien", notificationKey: "maintenanceReport", showIf: notifications.maintenanceReport },
      { path: "/documents?type=energy", icon: ArrowUpTrayIcon, label: "Factures de rénovation", notificationKey: "energyRenovation", showIf: notifications.energyRenovation },
      { path: "/documents?type=ownership", icon: IdentificationIcon, label: "Titre de propriété", notificationKey: "titleOfProperty", showIf: notifications.titleOfProperty },
      { path: "/documents?type=consumption", icon: ArrowUpTrayIcon, label: "Factures de consommation", notificationKey: "consumptionInvoice", showIf: notifications.consumptionInvoice },
    ];
  }, [notifications]);

  // Mémoriser si des actions sont requises
  const hasRequiredActions = useMemo(() => {
    return memoizedRequiredActions.some(action => action.showIf);
  }, [memoizedRequiredActions]);

  const renderNavItem = (item) => (
    <ListItem
      key={item.path}
      className={`cursor-pointer ${activeTab === item.path ? 'bg-gray-100' : ''}`}
      onClick={() => handleNavigation(item.path)}
    >
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center">
          <ListItemPrefix>
            <item.icon className="h-5 w-5" style={{ color: colors.dimoPrimary }} />
          </ListItemPrefix>
          <span style={{ color: colors.dimoPrimary }}>{item.label}</span>
        </div>
        {item.notificationKey && notifications[item.notificationKey] && (
          <span className="w-2 h-2 bg-red-500 rounded-full opacity-75"></span>
        )}
      </div>
    </ListItem>
  );

  const renderPlaceholder = () => (
    <div className="flex items-center space-x-4 px-4 py-3">
      <div className="w-5 h-5 bg-gray-200 rounded-full animate-pulse"></div>
      <div className="h-4 bg-gray-200 rounded w-24 animate-pulse"></div>
    </div>
  );

  const renderNewQuoteButtonPlaceholder = () => (
    <div className="flex items-center gap-3 mb-4 mx-4 h-10 bg-gray-200 rounded animate-pulse"></div>
  );

  return (
    <aside
      className={`
        fixed left-0 z-[80] w-64 h-dvh bg-white shadow-md
        transition-transform duration-300 ease-in-out transform
        ${isOpen ? 'translate-x-0' : '-translate-x-full'}
        lg:translate-x-0
        flex flex-col
      `}
    >
      <div className="p-4">
        <Link to="/dashboard" className="mx-auto">
          <Logo />
        </Link>
      </div>
      <div className="flex-1 overflow-y-auto">
        <List>
          {loading && !initialLoadComplete ? (
            <>
              {renderNewQuoteButtonPlaceholder()}
              {Array(5).fill(0).map((_, index) => (
                <React.Fragment key={index}>{renderPlaceholder()}</React.Fragment>
              ))}
            </>
          ) : (
            <>
              <Button
                style={{ backgroundColor: colors.dimoPrimary }}
                className="flex items-center gap-3 mb-4 mx-4"
                onClick={handleNewQuote}
              >
                <PlusIcon className="h-5 w-5" />
                Nouveau devis
              </Button>

              {/* Accueil */}
              {renderNavItem(memoizedNavItems[0])}

              {/* Devis */}
              {showQuote && renderNavItem(memoizedNavItems[1])}

              {/* Menu déroulant des actions requises */}
              {hasRequiredActions && (
                <div className="mt-2">
                  <ListItem
                    className="cursor-pointer"
                    onClick={() => setIsActionsOpen(!isActionsOpen)}
                  >
                    <div className="flex items-center justify-between w-full">
                      <div className="flex items-center">
                        <ListItemPrefix>
                          <ClipboardDocumentListIcon className="h-5 w-5" style={{ color: colors.dimoPrimary }} />
                        </ListItemPrefix>
                        <span style={{ color: colors.dimoPrimary }}>Actions à mener</span>
                      </div>
                      <div className="flex items-center gap-2">
                        {hasRequiredActions && (
                          <span className="w-2 h-2 bg-red-500 rounded-full opacity-75"></span>
                        )}
                        {isActionsOpen ? (
                          <ChevronUpIcon className="h-4 w-4" style={{ color: colors.dimoPrimary }} />
                        ) : (
                          <ChevronDownIcon className="h-4 w-4" style={{ color: colors.dimoPrimary }} />
                        )}
                      </div>
                    </div>
                  </ListItem>
                  
                  {isActionsOpen && (
                    <div className="ml-4">
                      {memoizedRequiredActions.map((action) => action.showIf && renderNavItem(action))}
                    </div>
                  )}
                </div>
              )}

              {/* Reste du menu principal */}
              {memoizedNavItems.slice(2).map((item) => (
                (item.alwaysShow || item.showIf) && renderNavItem(item)
              ))}
            </>
          )}
        </List>
      </div>
      <div className="flex flex-col gap-2 p-2 md:py-4 text-base">
        <ListItem
          className="cursor-pointer flex items-center justify-center md:justify-start"
          onClick={() => handleNavigation("/suggestion")}
        >
          <ListItemPrefix>
            <LightBulbIcon className="h-5 w-5" style={{ color: colors.dimoPrimary }} />
          </ListItemPrefix>
          <span style={{ color: colors.dimoPrimary }}>Suggérer une amélioration</span>
        </ListItem>
        <ListItem
          className="cursor-pointer flex items-center justify-center md:justify-start"
          onClick={() => handleNavigation("/technical-help")}
        >
          <ListItemPrefix>
            <ExclamationTriangleIcon className="h-5 w-5" style={{ color: colors.dimoPrimary }} />
          </ListItemPrefix>
          <span style={{ color: colors.dimoPrimary }}>Signaler un problème</span>
        </ListItem>
        <ListItem
          className="cursor-pointer flex items-center justify-center md:justify-start"
          onClick={handleLogout}
        >
          <ListItemPrefix>
            <UserIcon className="h-5 w-5" style={{ color: colors.dimoPrimary }} />
          </ListItemPrefix>
          <span style={{ color: colors.dimoPrimary }}>Déconnexion</span>
        </ListItem>
      </div>
    </aside>
  );
}
