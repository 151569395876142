import React, { useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/solid';

const ALLOWED_MIME_TYPES = {
  'image/jpeg': 'jpg',
  'image/png': 'png',
  'image/gif': 'gif',
  'image/webp': 'webp',
  'application/pdf': 'pdf',
  'application/msword': 'doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
  'application/vnd.ms-excel': 'xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
};

const MAX_FILE_SIZE = 100 * 1024 * 1024; // 100MB

const FileUpload = ({ onFileUpload }) => {
  const [pendingFiles, setPendingFiles] = useState([]);
  const [error, setError] = useState('');

  const validateFile = (file) => {
    if (!ALLOWED_MIME_TYPES[file.type]) {
      return `Le type de fichier ${file.type} n'est pas autorisé. Types acceptés : ${Object.keys(ALLOWED_MIME_TYPES).join(', ')}`;
    }
    if (file.size > MAX_FILE_SIZE) {
      return `Le fichier est trop volumineux. Taille maximum : ${MAX_FILE_SIZE / (1024 * 1024)}MB`;
    }
    return null;
  };

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    setError('');

    // Valider chaque fichier
    for (const file of newFiles) {
      const error = validateFile(file);
      if (error) {
        setError(error);
        return;
      }
    }

    // Ajouter les nouveaux fichiers à la liste des fichiers en attente
    const updatedFiles = [...pendingFiles, ...newFiles];
    setPendingFiles(updatedFiles);
    onFileUpload(updatedFiles);
  };

  const removeFile = (event, index) => {
    event.preventDefault();
    event.stopPropagation();
    
    const updatedFiles = pendingFiles.filter((_, i) => i !== index);
    setPendingFiles(updatedFiles);
    onFileUpload(updatedFiles);
  };

  return (
    <div className="space-y-4">
      <div className="border-2 border-dashed rounded-lg p-6 text-center transition-colors border-gray-300">
        <input
          type="file"
          multiple
          onChange={handleFileChange}
          className="hidden"
          id="file-upload"
          accept={Object.keys(ALLOWED_MIME_TYPES).join(',')}
        />
        <label htmlFor="file-upload" className="cursor-pointer">
          <div>
            <p>Glissez et déposez des fichiers ici, ou cliquez pour sélectionner</p>
            <p className="text-sm text-gray-500 mt-2">
              Types acceptés : {Object.values(ALLOWED_MIME_TYPES).map(ext => `.${ext}`).join(', ')}
            </p>
            <p className="text-sm text-gray-500">
              Taille maximum : {MAX_FILE_SIZE / (1024 * 1024)}MB
            </p>
          </div>
        </label>
      </div>

      {error && (
        <div className="mt-2 text-red-500 text-sm">
          {error}
        </div>
      )}

      {pendingFiles.length > 0 && (
        <div className="mt-4">
          <h4 className="font-semibold mb-2">Fichiers sélectionnés:</h4>
          <ul className="space-y-2">
            {pendingFiles.map((file, index) => (
              <li key={index} className="flex items-center justify-between bg-gray-100 p-2 rounded">
                <span className="truncate max-w-xs">{file.name}</span>
                <button
                  onClick={(e) => removeFile(e, index)}
                  type="button"
                  className="text-red-500 hover:text-red-700 focus:outline-none"
                  aria-label="Supprimer le fichier"
                >
                  <XMarkIcon className="h-5 w-5" />
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default FileUpload;

