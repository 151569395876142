import React, { useEffect } from 'react';

const ChatWidget = () => {
  useEffect(() => {
    if (!document.querySelector('script[src="https://static.elfsight.com/platform/platform.js"]')) {
      const script = document.createElement('script');
      script.src = "https://static.elfsight.com/platform/platform.js";
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);

  return (
    <div 
      className="elfsight-app-37878327-81e8-449f-8008-4bf8b944d597" 
      data-elfsight-app-lazy
      style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: 1000 }}
    />
  );
};

export default ChatWidget; 