import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Typography,
  Button,
  Checkbox,
  Textarea,
  Spinner,
  Card
} from "@material-tailwind/react";
import { ExclamationTriangleIcon, GiftIcon } from "@heroicons/react/24/outline";
import axiosInstance from "../utils/axiosInstance";
import { colors } from "../utils/colors";

const DECLINE_REASONS = [
  "Prix trop élevé",
  "Délais d'intervention trop longs",
  "Autre proposition retenue",
  "Projet reporté ou annulé",
  "Autre"
];

const DeclineQuote = ({ onSuccess, isComplete, onCancel }) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [comments, setComments] = useState('');

  const handleReasonToggle = (reason) => {
    setSelectedReasons(prev => 
      prev.includes(reason)
        ? prev.filter(r => r !== reason)
        : [...prev, reason]
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (selectedReasons.length === 0) {
      setError("Veuillez sélectionner au moins une raison");
      return;
    }

    setLoading(true);
    try {
      await axiosInstance.post(`/quotes/${id}/decline`, {
        reasons: selectedReasons,
        comments
      });
      setLoading(false);
      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      setError("Une erreur est survenue lors du refus du devis");
      setLoading(false);
    }
  };

  if (isComplete) {
    return (
      <div className="max-w-7xl mx-auto px-4 py-6">
        <div className="flex flex-col items-center justify-center mb-8">
          <div className="w-16 h-16 rounded-full bg-red-50 flex items-center justify-center mb-4">
            <ExclamationTriangleIcon className="h-8 w-8 text-red-500" />
          </div>
          <Typography variant="h4" className="text-center mb-2 text-gray-800">
            Refus enregistré
          </Typography>
          <Typography className="text-center text-gray-600 max-w-lg">
            Nous avons bien pris en compte votre décision et nous vous remercions de votre retour.
          </Typography>
        </div>

        <Card className="p-6 bg-gray-50">
          <div className="flex flex-col md:flex-row items-start gap-4">
            <div className="rounded-full bg-white p-3 shrink-0">
              <GiftIcon className="h-6 w-6" style={{ color: colors.dimoPrimary }} />
            </div>
            <div className="flex-1">
              <Typography variant="h6" className="text-gray-800 mb-2">
                Découvrez nos offres partenaires
              </Typography>
              <Typography className="text-gray-600">
                Profitez d'avantages exclusifs auprès de nos partenaires de confiance pour vos projets immobiliers.
              </Typography>
              <Button
                variant="filled"
                className="flex items-center gap-2 rounded-xl mt-4"
                style={{ backgroundColor: colors.dimoPrimary }}
                onClick={() => window.location.href = '/deals'}
              >
                <GiftIcon className="h-4 w-4" />
                Voir les bons plans
              </Button>
            </div>
          </div>
        </Card>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-6">
      <div className="flex flex-col items-center justify-center mb-8">
        <div className="w-16 h-16 rounded-full bg-red-50 flex items-center justify-center mb-4">
          <ExclamationTriangleIcon className="h-8 w-8 text-red-500" />
        </div>
        <Typography variant="h4" className="text-center mb-2 text-gray-800">
          Nous sommes désolés
        </Typography>
        <Typography className="text-center text-gray-600 max-w-lg">
          Votre retour est précieux pour nous aider à améliorer nos services. Pourriez-vous nous indiquer la raison de votre refus ?
        </Typography>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        <Card className="p-6">
          <div className="space-y-4">
            {DECLINE_REASONS.map((reason) => (
              <div key={reason} className="flex items-start gap-4">
                <Checkbox
                  checked={selectedReasons.includes(reason)}
                  onChange={() => handleReasonToggle(reason)}
                  className="checked:border-red-500 checked:bg-red-500"
                />
                <Typography className="mt-1 text-gray-800">
                  {reason}
                </Typography>
              </div>
            ))}
          </div>
        </Card>

        <Card className="p-6">
          <Typography className="text-gray-800 mb-2">Commentaire (facultatif) :</Typography>
          <Textarea
            value={comments}
            onChange={(e) => setComments(e.target.value)}
            rows={4}
            className="!border-gray-300"
            placeholder="Votre commentaire nous aidera à mieux comprendre vos besoins..."
          />
        </Card>

        {error && (
          <Typography color="red" className="text-center">
            {error}
          </Typography>
        )}

        <div className="flex justify-end gap-3">
          <Button
            type="button"
            variant="text"
            className="text-gray-700 hover:bg-gray-100"
            onClick={onCancel}
          >
            Retour
          </Button>
          <Button
            type="submit"
            variant="outlined"
            className="bg-white border-red-500 text-red-500 hover:bg-red-50"
            disabled={loading}
          >
            {loading ? (
              <Spinner className="h-6 w-6 mx-auto" />
            ) : (
              <>Confirmer mon refus</>
            )}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default DeclineQuote; 